.professionals-card {
    text-align: left;
    width: 9.6875rem;
}

.professionals-card__image {
    width: 9.6875rem;
}

.professionals-card__heading {
    @include transition(color);
    $color:  $color-woodsmoke;
    color: $color;
    font-size: 1rem;
    line-height: 1.0625;

    margin-top: 0.2rem;

    &:hover {
        color: $color-brand-orange;
    }
}

.professionals-card__title {
    color: $color-corduroy;
    font-size: 1rem;
    line-height: 1.0625;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

.professionals-card__icon-list {
    display: flex;
    margin-top: 0.2rem;
}

.professionals-card__icon {
    $color: $color-alto;

    @include transition(color);

    color: $color;
    display: inline-block;
    font-size: 1.875rem;
    margin-right: 0.4rem;
    cursor: pointer;

    &:hover {
        color: $color-brand-orange;
    }
}
