$load-more-form-submit-icon-transform: translate(-50%) translateY(50%);

.load-more-form {
    border-bottom: 1px solid $color-alto;
    margin-bottom: 1rem;
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    position: relative;
    text-align: center;
}

.load-more-form__submit-button {
    $color: $color-brand-green;

    @include transition(color);

    color: $color;
    font-size: 1.1875rem;
    font-weight: 700;

    &:hover {
        color: shade($color);

        .load-more-form__submit-button-icon {
            transform: $load-more-form-submit-icon-transform;

            color: $color-alto;
            &::before {
                background-color: $color-arrow-blue;
                border: 1px solid $color-alto;
            }
        }
    }
}

.load-more-form__submit-button-icon {
    @include circular-background($background-color: $color-alto, $height: 1.75rem, $transform: $load-more-form-submit-icon-transform, $hover: false);

    bottom: 0;
    color: $color-white;
    left: 50%;
    position: absolute;
}
