.services-landing-content {
    position: relative;

    &-clear-button {
        @include transition(transform);
        position: absolute;
        right: 0;

        &:hover {
            transform: scale($site-icon-hover-scale);
        }
    }
}

.services-landing-content__container {
    position: relative;
    width: 100%;

    @media (max-width: $bp-desktop) {
        margin-top: 0;
    }
}

.services-landing-content__view-button {
    @include transition(background-color, color, transform);
    background-color: $color-off-white;
    color: $color-brand-orange;
    padding: 1rem 5rem;
    display:flex;
    position: relative;

    &:last-of-type {
        margin-left: 0.2rem;
    }

    &--active {
        background-color: rgba(42, 96, 143, 1);
        color: $color-sage;
        transform: translateY(-3px);

        &::after {
            content: '';
            background-color: $color-sage;
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 3px;
            width: 100%;
        }
    }

    &-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 2rem;

        @media (max-width: $bp-desktop) {
            padding-top: 0;
        }
    }

    &-text {
        display: flex;
        font-size: 1.125rem;
        font-weight: 700;
    }

    @media (max-width: $bp-mobile) {
        width: 50%;
        padding: 1rem 0;

        &-inner {
            margin: 0 auto;
        }

        &-text {

        }
    }
}

.services-landing-content-section {
    $transition-duration: 0.6s;

    @include transition(opacity $transition-duration, visibility $transition-duration);

    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;

    &.services-landing-content-section--is-active {
        height: auto;
        opacity: 1;
        visibility: visible;
    }
}
