$icomoon-font-family: "vedder-price" !default;
$icomoon-font-path: '/Content/site/public/fonts/' !default;

$icon__twitter: "\e900";
$icon__loupe: "\e901";
$icon__open-book: "\e902";
$icon__hamburger: "\e903";
//legacy twitter $icon__twitter: "\e904";
$icon__linkedin: "\e905";
$icon__down-arrow: "\e906";
$icon__down-arrow-alt: "\e907";
$icon__close: "\e908";
$icon__vcard: "\e909";
$icon__envelope: "\e90a";
$icon__facebook: "\e90b";
$icon__clock: "\e90c";
$icon__printer: "\e90d";
$icon__pdf: "\e90e";
$icon__envelope-alt: "\e90f";
$icon__calendar: "\e910";
$icon__share: "\e911";
$icon__google-plus: "\e912";
$icon__loading: "\e913";
$icon__plus: "\e914";
$icon__minus: "\e915";
$icon__chevron-thin-left: "\e916";
$icon__chevron-thin-right: "\e917";
$icon__chevron-thin-up: "\e918";
$icon__chevron-thin-down: "\e919";
$icon__down-thin-arrow: "\e91a";
$icon__vcard-icon: "\e91b";
$icon__email-icon: "\e91c";