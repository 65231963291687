$site-search-padding: 1.5rem;

.site-search {
    display: none;
    position: relative;
    width: 50%;
    z-index: 20;

    @media (max-width: $bp-desktop) {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
    }

    @media (max-width: $bp-mobile) {
        width: 90%;
    }

    &.site-search--is-active {
        display: block;
    }
}

.site-search__input-container {
    position: relative;
}

.site-search__submit-button-text {
    @extend %visually-hidden;
}

.site-search__submit-button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.site-search__search-input {
    background-color: $color-white;
    color: $color-woodsmoke;
    display: block;
    font-size: 1.1rem;
    font-weight: 400;
    padding: $site-search-padding;
    width: 100%;
}

.site-search__submit-button-icon {
    @include transition(transform);

    display: inline-block;
    font-size: 1.5rem;

    &:hover {
        transform: scale($site-icon-hover-scale);
    }
}

.site-search__results {
    background-color: $color-white;
    border-top: 3px solid $color-brand-green;
    padding: $site-search-padding;

    table {
        border-collapse: collapse;
        border-spacing: 1rem;
        width: 100%;
    }

    tr {
        padding-bottom: 1.5rem;
    }

    span {
        $color: $color-alto;

        @include transition(color);

        color: $color;
        cursor: pointer;
        font-size: 1.05rem;
        font-weight: 400;

        &:hover {
            color: $color-woodsmoke;
        }
    }
}

.gss-results-container{
    padding-top: 1em;
}

.gss-result-container {
    &:after {
        height: 0;
        clear: both;
        visibility: hidden;
    }

    border-left: none;
    margin-bottom: 2.5rem;
    padding: 0 0 0 .5em;
    border-color: #ffffff;
    border-bottom: 0px solid;
    margin: 0;
    zoom: 1;
    position: static;
    display: block;
    visibility: visible !important;
    font-family: inherit !important;
    font-family: inherit !important;
    margin-bottom: 2.5rem;
}

.gss-result-text {

    vertical-align: top;
    width: 100%;
    font-family: "Open Sans","Avenir Next","Arial",sans-serif !important;
    font-size: 14px;
    text-align: left;
    line-height: 18px; 
}

.gss-result-title-container {
    font-size: 16px;
    text-decoration: underline;
    height: 1.4em;
    padding: 0;
    vertical-align: top;
    border-collapse: separate;
    text-align: left;
}

.gss-result-title {
    @include transition(color);
    $color: $color-brand-orange;
    &:hover{
        color: shade($color);
    }
    color: $color;
    font-size: 1.0625rem;
    font-weight: 600;
    text-decoration: underline;
    height: 1.4em;
    cursor: pointer;
    background-color: transparent;
    font-family: "Open Sans","Avenir Next","Arial",sans-serif !important;
    border-collapse: separate;
    text-align: left;
    transition: color .2s ease-in-out;
}

.gss-bottom-link{
       word-break: break-all;
       color: #fff;
       overflow: hidden;
       font-weight: bold;
       display: block;
       padding: 0;
       text-decoration: none;
       position: static;
       text-align: left;
       direction: ltr;
       unicode-bidi: isolate;
       font-size: 13px;
}

.gss-thumbnail-img-container {
    padding: 8px 8px 0 6px;
    vertical-align: top;
}

.gss-thumbnail-img {
    border: 1px solid #e2e2e2;
    max-width: 60px;
    max-height: 120px;
    cursor: pointer;
    color: inherit;
}