.professionals-card-grid {
    margin-bottom: 2rem;
}

.professionals-card-grid__heading {
    color: $color-mirage;
    font-size: 1.125rem;
    line-height: 1.1;
}

.professionals-card-grid__flex-container {
    $margin-horizontal: 1rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: .85rem;

    > * {
        margin-left: $margin-horizontal;
        margin-bottom: 0.5rem;

        @media (max-width: $bp-desktop) {
            margin-left: 0;
            margin-right: $margin-horizontal;
        }
    }

    @media (max-width: $bp-desktop) {
        justify-content: flex-start;
    }
}

.professionals-card-grid__view-all {
    @include transition(color);

    color: $color-hoki;
    font-size: 0.9375rem;

    &:hover {
        color: shade($color-hoki);
    }
}

// Offices Detail
.professionals-card-grid {
    &.professionals-card-grid--offices-detail {
        margin-top: 0.5rem;
    }
}

.professional-view-all {
    text-align: center;
    
    a {
        @include transition(color);
        $color: $color-brand-orange;

        color: $color;
        font-weight: 400;
    
        &:hover {
            color: shade($color);
        }
    }
}