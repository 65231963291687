.fly-in {
    $fly-in-duration: 0.65s;
    $fly-in-timing: cubic-bezier(.25,.1,.3,1.07);
    $translate-x: 4%;

    @include transition(transform $fly-in-duration $fly-in-timing, opacity $fly-in-duration $fly-in-timing);

    opacity: 0;

    &.fly-in--from-left {
        transform: translateX(-$translate-x);
    }

    &.fly-in--from-right {
        transform: translateX($translate-x);
    }

    &.fly-in--is-in-view {
        opacity: 1;
        transform: translateX(0);
    }
}
