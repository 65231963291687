.article-link {
  &__url {
    @include transition(color);
    $color: $color-brand-orange;
    color: $color;

    &:hover {
      color: shade($color);
    }
  }
}