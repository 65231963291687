// Basic tags
a {
    color: inherit;
    text-decoration: none;
}

body {
    color: $color-woodsmoke;
    font-family: $font-family-primary;
    line-height: 1.5;
    margin: 0;
    margin-left: auto;
    overflow-x: hidden;
    min-height: 100vh;
}

blockquote {
    quotes: "“" "”" "‘" "’";
}

button {
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
}

html {
    overflow-x: hidden;
}

p {
    font-size: 1rem;
    font-weight: 300;

    &:empty {

        display: none;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

// List tags
dl,
ol,
ul {
    margin-bottom: 0;
    margin-top: 0;
    list-style-type: none;
    padding-left: 0;
}

// Form tags
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    margin: 0;

}

fieldset,
input {
    border-width: 0;
}

input[type="reset"] {
    cursor: pointer;
}

input,
select {
    background-color: transparent;
}

input,
select,
textarea {
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none !important;
}

select {
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;

    &::-ms-expand {
        display: none;
    }
}

.print-only { display: none; }

.disclaimer {
    margin: 2rem;
    padding: 1rem;
    align-items: center;
    font-style: oblique;
    display: block;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}
