$site-header-drawer-transition-duration: 0.25s;
$site-header-drawer-transition-duration-mobile: 0.35s;

.site-header-drawer {
    $padding-vertical: 1rem;

    @include transition(height $site-header-drawer-transition-duration, visibility 0s $site-header-drawer-transition-duration);

    align-items: flex-start;
    background-color: $color-brand-green;
    display: flex;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 100%;

    @media (max-width: $bp-mobile) {
        box-sizing: border-box;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 6;
    }

    &.site-header-drawer--is-active {
        @include transition(height $site-header-drawer-transition-duration);

        visibility: visible;

        @media (max-width: $bp-mobile) {
            height: 100vh !important; // Overrides inline style set by site-header.js

            @include transition(height $site-header-drawer-transition-duration-mobile);

            .site-header-drawer__container {
                @include transition(opacity $site-header-drawer-transition-duration ($site-header-drawer-transition-duration / 2));

                opacity: 1;
            }
        }
    }
}

.site-header-drawer__container {
    $padding-vertical: 2.2rem;

    display: flex;
    justify-content: flex-end;
    margin-right: 7.5%;
    padding-bottom: $padding-vertical;
    padding-top: $padding-vertical;
    width: 100%;

    @media (max-width: $bp-desktop) {
        margin-right: auto;
    }

    @media (max-width: $bp-mobile) {
        @include transition(opacity $site-header-drawer-transition-duration);

        flex-direction: column;
        margin-bottom: auto;
        margin-top: 6rem;
        margin-bottom: 3rem;
        opacity: 0;
    }
}
