.vedder-thinking-tab {
    height: 0;
    opacity: 0;
    // overflow: hidden;
    visibility: hidden;

    &.vedder-thinking-tab--is-active {
        $transition-duration: 0.4s;

        @include transition(opacity $transition-duration, visibility $transition-duration);

        height: auto;
        opacity: 1;
        visibility: visible;
    }
}
