.professional-grid {    
    &__item {
        margin-bottom: 40px;
    }

    &__link {
        font-weight: 300;
        color: $color-brand-orange;
    }

    @media (min-width: $bp-mobile) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &__item {
            width: calc(50% - 16px);
        }
        &__item {
            margin-bottom: 32px;
        }
    }


    
    @media (min-width: $bp-desktop) {
        &:after {
            content: '';
            display: block;
            width: calc(33.33333% - 16px);
        }
        &__item {
            width: calc(33.33333% - 16px);
        }
    }
}