.standard-panel-nav {
    color: $color-white;
    position: fixed;
    right: 7.5%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 6;

    @media (max-width: $bp-desktop) {
        display: none;
    }

    &--careers-landing {
        width: 285px;

        @media (max-width: 1600px) {
            right: 4.5%;
        }   
        @media (max-width: 1400px) {
            right: 2.5%;
        }
        @media (max-width: 1179px) {
            display: none;
        }   
    }

    &.standard-panel-nav--has-text-anchors {
        .standard-panel-nav__anchor {
            margin-bottom: 1.25rem;
        }
    }

    &:not(.standard-panel-nav--has-text-anchors) {
        .standard-panel-nav__anchor {
            $width: 1rem;

            background-color: rgba($color-alto, 0.5);
            border-radius: 50%;
            height: $width;
            width: $width;

            &.standard-panel-nav__anchor--is-active,
            &:hover {
                background-color: $color-brand-orange;
            }
        }

    }
}

.standard-panel-nav__heading {
    color: $color-alto;
    font-family: $font-family-secondary;
    font-size: 1.5rem;
}

.standard-panel-nav__anchor-list-item {
    font-size: 1.125rem;

    &:not(:last-child) {
        .standard-panel-nav__anchor {
            margin-bottom: 0.5rem;
        }
    }
}

.professional-search-results__container-two {
    $padding-horizontal: 6.5%;

    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
}

.standard-panel-nav__anchor {
    @include transition(background-color, color);

    color: $color-alto;
    display: block;
    font-style: 1.125rem;
    font-weight: 700;

    &.standard-panel-nav__anchor--is-active,
    &:hover {
        color: $color-brand-orange;
    }
}
