.listing {
  &__item {
    & + & {
      margin-top: 8px;
    }
  }
  @media (min-width: $bp-mobile) {
    &__item {
      &+& {
        margin-top: 10px;
      }
    }
  }
}