.site-nav {
    $padding-vertical: 1rem;
    display: none;
    justify-content: flex-end;
    width: 100%;

    &.site-nav--is-active {
        display: flex;
    }
}

.site-nav__link-list {
    align-items: center;
    display: flex;

    @media (max-width: $bp-mobile) {
        $padding-horizontal: 2rem;

        flex-direction: column;
        flex-grow: 1;
        margin-bottom: auto;
        margin-right: auto;
        margin-top: 2rem;
        padding-left: $padding-horizontal;
        padding-right: $padding-horizontal;
    }
}

.site-nav__link-list-item {
    box-sizing: border-box;
    color: $color-white;
    margin-left: 3.5rem;

    @media (max-width: $bp-desktop) {
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    @media (max-width: $bp-mobile) {
        margin-left: 0;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    a {
        @include transition(color);

        color: $color-white;
        font-size: 1.1875rem;
        font-weight: 400;

        @media (max-width: $bp-mobile) {
            border-bottom: 1px solid;
            display: block;
            padding-bottom: 0.3rem;
            width: 100%;
        }

        &:hover {
            color: $color-brand-blue;
        }
    }
}
