.detail-tabs {
    box-sizing: border-box;
    color: black;
    display: flex;
    height: calc(100vh - #{$site-header-height});
    margin-left: auto;
    margin-right: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding: 1%;
    position: relative;
    text-align: right;
    width: 42%;
    z-index: 5;

    @media (max-width: $bp-desktop) {
        background-color: $color-white;
        flex-direction: column;
        height: auto;
        overflow: visible;
        padding: 4%;
        text-align: left;
        width: 100%;
    }

    @media (max-width: $bp-mobile-small) {
        padding: 7%;
    }


    &__viewmore-link {
        $color: $color-brand-lighter-blue;

        @include transition(background-color);

        background-color: $color;
        color: $color-white;
        display: inline-block;
        font-size: 1.1875rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
        margin-bottom: 0.5rem;
        text-align: left;

        @media (max-width: $bp-desktop-vedder-thinking) {
            font-size: 0.9rem;
        }

        @media (max-width: $bp-mobile) {
            padding: 0.5rem 1rem;
        }

        @media (max-width: 22.25rem) {
            padding: 0;
            color: $color;
            background-color: transparent;
        }

        &:hover {
            background-color: shade($color);
        }
    }
    &__careers-detail {
        width: 100%;
        flex-direction: column;
        text-align: left;
        padding: 4rem 6rem;
        height: auto;
        overflow-y: hidden;

        &--abstract {
            margin-bottom: 2rem;
            color: black;
            font-size: $text-default;
            font-weight: 300;

            p {
                color: black;
                font-size: $text-default;
                font-weight: 300;
            }
        }

        .detail-tabs__container {
            width: 100%;

            .detail-tab__richtext-container {
                h2 {
                    color: black;
                }

                p {
                    color: black;
                    font-size: $text-default;
                    font-weight: 300;
                }

                @media (max-width: $bp-mobile) {
                    h2 {
                        font-size: $text-large;
                    }

                    p {
                        font-size: $text-default;
                    }
                }
            }

            .detail-tab {
                // overflow-y: visible;
            }
        }
    }
}

.detail-tabs__container {
    width: calc((100% - 84px) - 1.5rem);

    @media (max-width: $bp-desktop) {
        order: 1;
    }
}
