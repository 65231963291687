.professionals-detail-tab {

    h2 {
        color: $color-brand-blue;
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    ul {
        margin-bottom: 2rem;
    }

    li {
        color: $color-woodsmoke;
        font-size: 1rem;
        line-height: 1.6875;
        margin-bottom: .75rem;

        .email-disclaimer--js {
            $color: $color-woodsmoke;
            color: $color;

            &:hover {
                color: $color-brand-orange;
            }
        }
    }

    .professionals-detail-tab__name {
        margin-bottom: 3rem;
        
        > h1 {
            color: $color-mirage;
            font-size: 2.125rem;
            line-height: 0.9;
        }

        > p {
            color: $color-blue-charcoal;
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .detail-tab__generic-container {

        $generic-container-section-spacing: 1.5rem;
        $generic-container-item-spacing: .75rem;

        p {
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: $generic-container-section-spacing;
        }

        ul {

            margin-top: 0;
            margin-bottom: $generic-container-section-spacing;

            li {

                font-size: 1rem;
                margin-top: 0;
                margin-bottom: $generic-container-item-spacing;

            }

        }

    }

}

.professionals-detail-tab__credential-list {
    color: $color-black-pearl;
    font-size: 1.25rem;
    line-height: 1.3;
}

.professionals-detail-tab__contact-info {
    color: $color-dark-slate;

    @media (max-width: $bp-desktop) {
        margin-top: 1.5rem;
    }

    &, strong {
        font-size: 1.0625rem;
        font-weight: 400;
    }

    strong {
        color: $color-woodsmoke;
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    a strong{
        @include transition(color);
        $color: $color-woodsmoke;
        color: $color;

        &:hover {
            color: $color-brand-orange;
        }
    }
}

.professionals-detail-tab__contact-list {
   display:inline-block;
    .professionals-detail-tab__contact-list-item {
        margin-bottom: 0;
        @extend .professionals-detail-tab__social-icon;
    }
}

.professionals-detail-tab__social-list {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $bp-desktop) {
        justify-content: flex-start;
    }
}

.professionals-detail-tab__social-list-item {
    font-size: 2.1rem;

    &:not(:last-of-type) {
        margin-right: 0.75rem;
    }
}

.professionals-detail-tab__social-icon {
    $color: $color-alto;
    cursor: pointer;

    @include transition(color);

    &:hover {
        color: shade($color);
    }
}

.professionals-detail-tab__bio {
    h2 {
        color: $color-corduroy;
        font-size: 1.3125rem;
        font-weight: 300;
        line-height: 1.28;
        margin: 1.5rem 0;
    }

    p {
        color: $color-brand-blue;
        font-size: 0.9375rem;
        font-weight: 300;
    }
}

.professionals-detail-tab__services {

    a {
        $color: $color-woodsmoke;
        color: $color;

        @include transition(color);

        &:hover {
            color: $color-brand-orange;
        }
    }

}

.professionals-detail-tab__education {
    color: $color-brand-blue;
    margin-bottom: 0.5rem;
}

.professionals-detail-tab__contact-link {
    @include transition(color);
    $color: $color-woodsmoke;
    color: $color;

    &:hover {
        color: $color-brand-orange;
    }
}
