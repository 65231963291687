.subscription-link {
    $color: $color-white;
    $background-color: $color-brand-lighter-blue;

    @include transition(background-color, color);

    color: $color;
    display: inline-block;
    font-size: 1.1875rem;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0.5rem 5rem;
    background-color: $background-color;

    @media (max-width: $bp-desktop-vedder-thinking) {
        font-size: 0.9rem;
    }

    &:hover {
        background-color: shade($background-color);
    }
}