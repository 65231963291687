div.professional-typeahead-list {
    position: absolute;
    left: 87px;
    top: 40px;
    padding: 5px;
    background: rgba($color-white, 0.8);
    color: black;
    z-index: 12;
    min-width: 50%;

    visibility: hidden;

    &.active {
        visibility: visible;
    }

    ul {
        padding: 5px;
    }

    @media (max-width: $bp-desktop) {
        left: 86px;
        top: 40px;
    }

    @media (max-width: $bp-mobile) {
        left: 71px;
        top: 32px;
    }

    li.professional-typeahead-item {
        padding: 2px 0 2px 0;
    }

    li.professional-typeahead-item:hover {
        border: 1px solid $color-brand-blue;
        color: $color-brand-blue;
    }

    a.professional-typeahead-link {
        display: block;
        width: 100%;
    }
}