.detail-tabs-nav {
    margin-left: 1.5rem;
    position: relative;
    min-width: 5rem;

    @media (max-width: $bp-desktop) {
        margin-left: 0;
        padding-left: 0;
    }

    &.detail-tabs-nav--is-active {
        .detail-tabs-nav__menu-button {
            @include transition(opacity);

            @media (max-width: $bp-desktop) {
                opacity: 0;
            }
        }

        .detail-tabs-nav__list-container {
            opacity: 1;
            visibility: visible;
            position:static;
        }
    }

    &__careers-detail {
        margin-left: 0;

        .detail-tabs-nav__list {
            display: flex;

            &-item:not(:last-child) {
                padding-right: 2rem;
            }

            &-item {
                .detail-tabs-nav__anchor {
                    height: 7rem;
                    width: 7rem;
                    font-size: 1.35rem;

                    &--pad-bot {
                        padding-bottom: 1rem;
                    }
                }
            }

            @media (max-width: $bp-desktop) {
                display: block;
                &-item {
                    .detail-tabs-nav__anchor {
                        height: auto;
                        width: auto;
                        font-size: 1.185rem;
                    }
                }
            }
        }
    }
}

.detail-tabs-nav__list-container {
    @include transition(opacity, height, visibility);

    @media (max-width: $bp-desktop) {
        background-color: $color-brand-green;
        box-shadow: 1px 1px 1px $color-box-shadow;
        box-sizing: border-box;
        height: 0;
        left: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0.75rem;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 75%;
    }

    @media (max-width: $bp-tablet) {
        position: static;
    }
}

.detail-tabs-nav__menu-button {
    @include circular-background($background-color: $color-alto);

    color: $color-white;
    display: none;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @media (max-width: $bp-desktop) {
        display: block;
    }
}

.detail-tabs-nav__list-item {
    @media (max-width: $bp-desktop) {
        $padding-vertical: 0.5rem;

        color: $color-white;
        font-size: 1.05rem;
        padding-top: $padding-vertical;

        a {
            display: inline-block;
            width: 100%;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $color-white;
            padding-bottom: $padding-vertical;
        }
    }
}

.detail-tabs-nav__menu-close-button {
    color: $color-white;
    display: none;
    font-size: 1.5rem;
    padding-left: 0;

    @media (max-width: $bp-desktop) {
        display: block;
    }
}

.detail-tabs-nav__anchor {
    $background-color: $color-alto;
    $height: 5.4rem;

    color: $color-white;
    font-family: $font-family-primary--condensed;
    font-size: 1.1875rem;
    line-height: 1.1;

    @media (min-width: $bp-desktop) {
        @include circular-background($background-color: $background-color, $height: $height);

        border: 2px solid $background-color;
        margin-bottom: 0.75rem;

        &.detail-tabs-nav__anchor--is-selected {
            @include circular-background($background-color: $color-white, $height: $height);

            color: $background-color;
        }
    }
}
