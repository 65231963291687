.back-to-top__anchor {
    $site-transition-duration: 0.7s;

    @include transition(opacity $site-transition-duration, visibility $site-transition-duration);

    background-color: rgba($color-hoki, 0.83);
    color: $color-white;
    font-size: 0.9375rem;
    line-height: 1;
    opacity: 0;
    padding: 0.5rem;
    padding-right: 0.8rem;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    z-index: 20;

    @media (max-width: $bp-desktop) {
        display: none;
    }

    &.back-to-top--is-visible {
        opacity: 1;
        visibility: visible;
    }
}

.back-to-top__icon {
    @include circular-background($transform: rotate(180deg), $hover: false);

    font-size: 1.4rem;
    transform: rotate(180deg);
}

.back-to-top__text {
    display: block;
    font-family: $font-family-secondary;
    margin-top: 0.5rem;
    text-transform: uppercase;
}
