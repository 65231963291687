$content-container-margin-extra-small: 5%;
$content-container-margin-small: 15%;
$standard-panel-background-color: $color-white;
$standard-panel-padding: 15%;
$standard-panel-padding-mobile: 5%;
$standard-panel-skew: 21.5deg;
$standard-panel-width: 90vw;
.standard-panel {
    $local-time-positioning: 10%;
    $content-container-margin-large: 30%;
    $content-container-position-horizontal: 5%;
    background-color: $color-white;
    background-position: 50% 50%;
    box-sizing: border-box;
    color: $color-white;
    padding-bottom: $standard-panel-padding;
    padding-top: $standard-panel-padding;
    position: relative;
    width: $standard-panel-width;
    overflow: hidden;
    @media (max-width: $bp-desktop) {
        padding: $standard-panel-padding-mobile;
        width: 100%;
    }
    &:nth-of-type(2n+1):not(:only-of-type) {
        margin-left: auto;
        .standard-panel__more-anchor {
            right: 0;
            @media (max-width: $bp-desktop) {
                margin-left: auto;
            }
        }
        .standard-panel__more-anchor-icon {
            transform: rotate(90deg);
        }
        .standard-panel__local-time-container {
            left: $local-time-positioning;
            top: $local-time-positioning;
        }
        .standard-panel__introduction-highlights-container {
            margin-left: auto;
            @media (max-width: $bp-desktop) {
                align-items: flex-end;
            }
            img {
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
        .standard-panel__content-container {
            margin-left: $content-container-margin-large;
            margin-right: $content-container-margin-small;
            @media (max-width: $bp-desktop) {
                margin-left: auto;
                margin-right: 0;
            }
        }
        .standard-panel__navigation-container {
            margin-left: auto;
            border-bottom: 1px solid $color-white;
            @media (max-width: $bp-desktop) {
                align-items: flex-end;
            }
        }
    }
    &:nth-of-type(2n) {
        @media (max-width: $bp-desktop) {
            .standard-panel {
                padding-left: $standard-panel-padding-mobile;
            }
        }
    }
    &:nth-of-type(2n),
    &:only-of-type {
        .standard-panel__more-anchor {
            @media (max-width: $bp-desktop) {
                margin-left: 0;
                margin-right: auto;
            }
        }
        .standard-panel__more-anchor-icon {
            transform: rotate(-90deg);
        }
        .standard-panel__local-time-container {
            right: $local-time-positioning;
            top: $local-time-positioning;
        }
        .standard-panel__local-time-label {
            text-align: right;
        }
        .standard-panel__introduction-highlights-container {
            margin-right: auto;
            @media (max-width: $bp-desktop) {
                align-items: flex-start;
            }
            img {
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
        .standard-panel__navigation-container {
            margin-left: 0;
            border-bottom: 1px solid $color-white;
            margin-top: 2rem;
            @media (max-width: $bp-desktop) {
                align-items: flex-start;
                margin-right: 0;
            }
        }
        .standard-panel__content-container {
            justify-content: center;
            margin-left: $content-container-margin-small;
            margin-right: $content-container-margin-large;
            @media (max-width: $bp-desktop) {
                align-items: flex-start;
                margin-left: 0;
                margin-right: 0;
                text-align: left;
            }
        }
        .standard-panel__introduction,
        .standard-panel__primary-heading {
            @media (max-width: $bp-desktop) {
                text-align: left;
            }
        }
    }
    &:only-of-type {
        height: auto;
        .standard-panel {
            padding-bottom: 0;
        }
        .standard-panel__background-image {
            .standard-panel__second-background-image {
                display: block;
            }
        }
        .standard-panel__content-container {
            position: relative;
            top: 0;
        }
        .standard-panel__primary-heading {
            text-align: left;
            span:nth-of-type(1) {
                display: block;
            }
            span:nth-of-type(3) {
                margin-right: 0;
            }
        }
    }
}

.standard-panel__introduction {
    font-size: 1rem;
    position: relative;
    //text-align: right;
    a {
        @include transition(background-color, color);
        color: $color-brand-green;
        &:hover {
            color: shade($color-brand-green);
        }
    }
    p {
        font-size: 1.125rem;
    }
    li {
        a {
            $color: $color-brand-orange;
            color: $color;
            &:hover {
                color: shade($color);
            }
            &:not(:last-of-type) {
                margin-bottom: 1.125rem;
            }
        }
        p {
            font-size: 0.9375rem;
            font-weight: 400;
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
    span.date-text {
        margin-right: 6px;
        font-size: $text-small;
    }
    @media (max-width: $bp-desktop) {
        font-size: 0.8rem;
    }

    &--careers-open-position {
        margin-top: 3rem;
        padding-bottom: 1rem;
    }
}

.standard-panel__more-anchor {
    $background-color: $color-brand-orange;
    @include circular-background($background-color: $color-brand-orange, $height: 4.375rem, $hover: false);
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-top: 1rem;
    justify-content: center;
    //right: 0;
    position: absolute;
    top: 100%;
    @media (max-width: $bp-desktop) {
        margin-left: 0;
        position: static;
    }
    &:hover {
        background-color: shade($background-color);
    }
}

.standard-panel__more-anchor-icon,
.standard-panel__more-anchor-text {
    align-content: center;
    color: $color-white;
}

.standard-panel__more-anchor-icon {
    font-size: 1.4rem;
}

.standard-panel__more-anchor-text {
    font-size: 0.875rem;
    position: relative;
    top: -0.6rem;
}

.standard-panel__content-container {
    max-width: 60rem;
    position: relative;
    text-align: left;
    z-index: 5;
    @media (max-width: $bp-desktop) {
        margin-left: 0;
        text-align: right;
    }
}

.standard-panel__primary-heading {
    color: $color-white;
    line-height: 1;
    position: relative;
    //text-align: right;
    span:nth-of-type(1) {
        $color: $color-alto;
        color: $color;
        font-family: $font-family-secondary;
        @media (max-width: $bp-mobile) {
            font-size: 1.25rem;
        }
    }
    span:nth-of-type(2) {
        display: inline-block;
        font-size: 4.5rem;
        margin-right: 5%;
        @media (max-width: $bp-desktop) {
            display: block;
            margin-right: 0;
        }
        @media (max-width: $bp-mobile) {
            font-size: 3rem;
        }
    }
    span:nth-of-type(3),
    span:only-of-type {
        color: $color-white;
        display: block;
        font-size: 7.25rem;
        line-height: 0.8;
        @media (max-width: $bp-mobile) {
            font-size: 4rem;
        }
    }
}

.standard-panel__highlights {
    text-transform: uppercase;
    @media (max-width: $bp-desktop) {
        font-size: 0.9rem;
    }
    q,
    p {
        font-weight: 700;
    }
    q {
        display: block;
        quotes: none;
    }
    cite {
        font-family: $font-family-primary--condensed;
        font-style: italic;
        font-weight: 300;
        margin-left: 0;
        display: block;
    }
    >* {
        font-size: 1.5rem;
        line-height: 0.9;
        margin-bottom: 0;
        margin-top: 0;
        @media (max-width: $bp-desktop) {
            font-size: 1.25rem;
        }
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }
}

.standard-panel__navigation-container {
    display: flex;
    margin-top: 2rem;
    @media (max-width: $bp-desktop) {
        margin-top: 1.25rem;
        width: 75vw;
    }
    @media (max-width: $bp-mobile) {
        width: 95%;
    }
    @media (max-width: $bp-mobile-small) {}
    @media (max-width: $bp-desktop) {
        flex-direction: column;
        > :first-child {
            order: 1;
        }
    }
    >* {
        &:not(:only-child) {
            width: 65%;
            @media (max-width: $bp-mobile) {
                width: auto;
            }
        }
        &:last-child:not(:only-child) {
            $border: 1px solid $color-white;
            $spacing: 1rem;
            border-left: $border;
            margin-left: $spacing;
            padding-left: $spacing;
            width: 30%;
            @media (max-width: $bp-desktop) {
                border-bottom: $border;
                border-left-width: 0;
                margin-bottom: $spacing;
                margin-left: 0;
                padding-bottom: $spacing;
                padding-left: 0;
                width: auto;
            }
        }
    }
}

.standard-panel__introduction-highlights-container {
    display: flex;
    margin-top: 2rem;
    @media (max-width: $bp-desktop) {
        margin-top: 1.25rem;
        width: 75vw;
    }
    @media (max-width: $bp-mobile) {
        width: 95%;
    }
    @media (max-width: $bp-mobile-small) {}
    @media (max-width: $bp-desktop) {
        flex-direction: column;
        > :first-child {
            order: 1;
        }
    }
    >* {
        &:not(:only-child) {
            width: 65%;
            @media (max-width: $bp-mobile) {
                width: auto;
            }
        }
        &:last-child:not(:only-child) {
            $border: 1px solid $color-white;
            $spacing: 1rem;
            border-left: $border;
            margin-left: $spacing;
            padding-left: $spacing;
            width: 30%;
            @media (max-width: $bp-desktop) {
                border-bottom: $border;
                border-left-width: 0;
                margin-bottom: $spacing;
                margin-left: 0;
                padding-bottom: $spacing;
                padding-left: 0;
                width: auto;
            }
        }
    }
    img {
        max-width: 100%;
        width: auto;
        height: auto;
    }
}

.standard-panel {
    .standard-panel__overlay {
        background-color: $color-image-overlay;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
        @media (max-width: $bp-desktop) {
            width: 100%;
        }
    }
}

.standard-panel__service-landing-overlay {
    background-color: $color-image-overlay;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @media (max-width: $bp-desktop) {
        width: 100%;
    }
}

.standard-panel__local-time-container {
    position: absolute;
    z-index: 5;
    @media (max-width: $bp-mobile) {
        display: none;
    }
}

.standard-panel__local-time {
    font-weight: 300;
    font-size: 1.625rem;
    margin-left: 0.3rem;
}

.standard-panel__local-time-icon {
    font-size: 1.7rem;
}

.standard-panel__local-time-label {
    line-height: 0.9;
    font-family: $font-family-primary--condensed;
    margin-bottom: 0.3rem;
    font-size: 1.1875rem;
}

.standard-panel__background-image {
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
}

.standard-panel__image {
    margin-bottom: -0.4rem;
    width: 100%;
    z-index: 0;
}

.standard-panel__decoration-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.standard-panel__service-landing-decoration-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @media (max-width: $bp-mobile) {
        height: 100%;
    }
}

.standard-panel__article-author {
    color: $color-white;
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0.4rem;
}

.standard-panel__article-author-link {
    &:hover {
        color: shade($color-white);
    }
}

.standard-panel__article-author-by {
    float: left;
}

.standard-panel__article-author-list {
    float: left;
    margin-left: 0.5rem;
}

.standard-panel__article-publication {
    color: $color-white;
    font-size: 1.25rem;
    line-height: 1.6;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
    clear: left;
}

.standard-panel__article-date {
    color: $color-white;
    margin-bottom: 0;
    margin-top: 0;
}

.standard-panel__tag {
    color: $color-sage;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.standard-panel__register-container {
    align-items: center;
    display: flex;
}

.standard-panel__event-register-link {
    $background-color: $color-brand-orange;
    @include circular-background($background-color: $background-color, $height: 5.0625rem, $hover: false);
    font-size: 1.1875rem;
    font-family: $font-family-primary--condensed;
    margin-top: 0.3rem;
    &:hover {
        background-color: shade($background-color);
    }
}

.standard-panel__event-add-to-calendar {
    @include transition(color);
    align-items: center;
    color: $color-alto;
    display: inline-flex;
    margin-left: 0.5rem;
    &:hover {
        span {
            color: $color-white;
        }
    }
    span:nth-of-type(1) {
        font-size: 2rem;
    }
    span:nth-of-type(2) {}
}

.standard-panel__primary-heading-link{
    position:relative;
    z-index:900;
    
    &--homepage {
        @include transition(color);
        $color: $color-brand-orange;
        color: $color;

        &:hover{
            color: shade($color);
        }
    }
} 

.standard-panel__primary-heading-sub-text {
    font-size: 1.625rem;
    font-weight: 300;
    line-height: 1;
    p {
        &:first-of-type {
            margin-top: 0;
        }
    }
}

// Professionals Landing, Site Search Results
.standard-panel {
    &.standard-panel--professionals-landing,
    &.standard-panel--site-search-results {
        padding-top: 5%;
        padding-bottom: 0;
        width: 85vw;
        @media (max-width: $bp-desktop) {
            padding-left: 0;
            padding-right: 0;
            width: auto;
        }
        .standard-panel__content-container {
            margin-left: $content-container-margin-small / 2;
            @media (max-width: $bp-desktop) {
                margin-left: auto;
                margin-right: auto;
                width: 95vw;
            }
        }
    }
}

// Services Landing
.standard-panel {
    &.standard-panel--services-landing {
        padding-top: 5%;
        .standard-panel__introduction-highlights-container {
            width: 42%;
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
            img {
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
        .standard-panel__navigation-container {
            width: 42%;
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
        .standard-panel__introduction {
            text-align: left;
        }
        .standard-panel__content-container {
            $margin-horizontal: 10%;
            margin-left: $margin-horizontal;
            margin-right: $margin-horizontal;
        }
    }
}

// Professionals Landing, Services Landing, Site Search Results
.standard-panel {
    &.standard-panel--services-landing,
    &.standard-panel--professionals-landing,
    &.standard-panel--site-search-results {
        width: 100%;
        .standard-panel__background-image {
            background-size: 100%;
            @media (max-width: $bp-mobile) {
                background-size: 100vh;
            }
        }
        .standard-panel__decoration-container {
            width: 80%;
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
        .standard-panel__service-landing-decoration-container {
            width: 80%;
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
        .standard-panel__content-container {
            max-width: none;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            @media (max-width: $bp-mobile) {
                width: 95%;
            }
        }
    }
}

// Detail pages
.standard-panel {
    &.standard-panel--detail-page {
        margin-right: auto;
        padding-bottom: 5%;
        padding-top: 5%;
        width: 95vw;
        @media (max-width: $bp-desktop) {
            padding: 0;
            width: 100%;
        }
        .standard-panel__background-image {
            background-size: 100%;
        }
        .standard-panel__decoration-container {
            height: 100vh;
            @media (max-width: $bp-desktop) {
                height: 100%;
            }
        }
        .standard-panel__decoration-container,
        .standard-panel__content-container {
            width: 60%;

            &--careers-detail {
                width: 100%;
                height: 100%;
            }
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
        &.standard-panel--professionals-detail {
            .standard-panel__decoration-container {
                @media (max-width: $bp-desktop) {
                    position: static;
                    height: 25%;
                }
                .standard-panel__background-image {
                    @media (max-width: $bp-desktop) {
                        width: 95%;
                        margin: 0 2.5%;
                    }
                }
            }
            .detail-tabs {
                @media (max-width: $bp-desktop) {
                    margin-top: 50%;
                }
            }
        }
        .standard-panel__introduction {
            text-align: left;
        }
        .standard-panel__content-container {
            left: 0;
            margin-left: 0;
            padding-left: 5%;
            position: absolute;
            text-align: left;
            top: 5rem;
            width: 30%;
            @media (max-width: $bp-desktop) {
                margin-top: 0;
                position: relative;
                top: 0;
                width: 95%;
            }
        }
        .standard-panel__callout-container {
            margin: 2rem 0 0 1rem;
        }
        .standard-panel__callout-text {
            $color: $color-white;
            @include transition(color);
            color: $color;
            font-size: 1.875rem;
            font-weight: 300;
            &:hover {
                color: shade($color);
            }
            @media (max-width: $bp-desktop) {
                font-size: 1.5rem;
            }
        }
    }
}

// Services Detail
.standard-panel {
    &.standard-panel--services-detail {
        .standard-panel__content-container {
            margin-left: 8vw;
            padding-left: 0;
            @media (max-width: $bp-desktop) {
                $padding-vertical: 10%;
                margin-left: 0;
                padding-top: $padding-vertical;
                padding-bottom: $padding-vertical;
            }
        }
    }
}

// Professionals Detail
.standard-panel {
    &.standard-panel--professionals-detail {
        .standard-panel__content-container {
            padding-top: 60%;
        }
    }
}

// Offices Detail
.standard-panel {
    &.standard-panel--offices-detail {
        .standard-panel__local-time-label {
            display: none;
        }
        .standard-panel__local-time-container {
            right: 3.5rem;
            top: 1.5rem;
        }
        .standard-panel__content-container {
            padding-top: 5%;
            @media (max-width: $bp-desktop) {
                $padding-vertical: 10%;
                padding-top: $padding-vertical;
                padding-bottom: $padding-vertical;
            }
        }
    }
}

// Offices Landing
.standard-panel {
    &.standard-panel--offices-landing {
        $content-container-margin-large: 25%;
        $content-container-margin-small: 22%;
        $content-container-margin-relative: 8vw;
        .standard-panel__primary-heading {
            span:nth-of-type(1) {
                font-family: $font-family-primary;
                font-size: 4.375rem;
                @media (max-width: $bp-desktop) {
                    font-size: 4rem;
                }
                @media (max-width: $bp-mobile) {
                    font-size: 3.25rem;
                }
            }

        }
        &:nth-of-type(2n+1) {
            .standard-panel__content-container {
                margin-left: $content-container-margin-small;
                margin-right: $content-container-margin-large;
                @media (max-width: $bp-desktop) {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
        &:nth-of-type(2n) {
            .standard-panel__content-container {
                margin-left: $content-container-margin-relative;
                margin-right: $content-container-margin-large;
                @media (max-width: $bp-desktop) {
                    margin-right: 0;
                    margin-left: auto;
                }
            }
        }

        .standard-panel__primary-heading {
            @include transition(border-bottom, color);
            display: inline;
            border-bottom: 3px solid rgba($color-gray, 0);
            padding-bottom: 0.5rem;

            &-link {
                display: inline;
                font-family: $font-family-primary;
                font-size: 4.375rem;
                @media (max-width: $bp-desktop) {
                    font-size: 4rem;
                }
                @media (max-width: $bp-mobile) {
                    font-size: 3.25rem;
                }
            }

            &:hover {
                color: $color-gray;
                border-bottom: 3px solid rgba($color-gray, 1);
            }
        }
    }
}

// Offices Detail and Offices Landing
.standard-panel {
    &.standard-panel--offices-landing,
    &.standard-panel--offices-detail {
        .standard-panel__introduction {
            p {
                font-size: 1.625rem;
                font-weight: 300;
                line-height: 1.384615385;
                margin-bottom: 0;
                margin-top: 0;
                @media (max-width: $bp-desktop) {
                    font-size: 1.2rem;
                }

                // a {
                //     @include transition(border-bottom, color, text-decoration-color);
                //     color: $color-white;
                //     text-decoration: underline;
                //     text-decoration-color: transparent;
                //     &:hover {
                //         color: $color-gray;
                //         text-decoration-color: $color-gray;
                //     }
                // }

                a {
                    color: $color-white;
                    &:hover {
                        color: $color-brand-orange;
                    }
                }
            }
        }
    }
}

//Careers Landing
.standard-panel {
    &.standard-panel--careers-landing {
        $content-container-margin-large: 32%;
        $content-container-margin-small: 10%;
        .standard-panel__primary-heading {
            span:nth-of-type(1) {
                font-family: $font-family-primary;
                font-size: 4.375rem;
                @media (max-width: $bp-desktop) {
                    font-size: 4rem;
                }
                @media (max-width: $bp-mobile) {
                    font-size: 3.25rem;
                }
            }
        }
        &:nth-of-type(2n+1) {
            .standard-panel__content-container {
                margin-left: $content-container-margin-small;
                margin-right: $content-container-margin-large;
                text-align: right;
                @media (max-width: $bp-desktop) {
                    margin-left: 0;
                    margin-right: auto;
                }
                .standard-panel__introduction {
                    margin-left: 20%;
                }
            }
        }
        &:nth-of-type(2n) {
            .standard-panel__content-container {
                margin-left: 5%;
                margin-right: $content-container-margin-large;
                text-align: left;
                @media (max-width: $bp-desktop) {
                    margin-right: 0;
                    margin-left: auto;
                }
                .standard-panel__introduction {
                    margin-right: 20%;
                }
            }
        }

        .standard-panel__primary-heading {
            display: inline;
            border-bottom: 3px solid rgba($color-careers-grey, 0);
            @include transition(background-color, border-bottom, color);


            &:hover {
                color: $color-careers-grey;
                border-bottom: 3px solid rgba($color-careers-grey, 1);

                &::after {
                    display: none;
                }
            }

            @media (max-width: $bp-mobile) {
                display: inline;
                font-size: 3.25rem;
            }
        }

        .standard-panel--careers-landing__title {
            font-family: $font-family-primary;
            font-size: 4.375rem;
            display: inline;
            
            @media (max-width: $bp-desktop) {
                display: inline;
                font-size: 4rem;
            }
            @media (max-width: $bp-mobile) {
                display: inline;
                font-size: 3.25rem;
                line-height: 1.2;
            }
            
        }
    }
}

.standard-panel {
    &.standard-panel--careers-landing,
    &.standard-panel--careers-detail {
        .standard-panel__introduction {
            margin-top: 1em;
            p {
                font-size: 1.2rem;
                font-weight: 300;
                margin-bottom: 0;
                margin-top: 0;
                @media (max-width: $bp-desktop) {
                    font-size: 1.2rem;
                }
                a {
                    color: $color-white;
                    &:hover {
                        color: $color-brand-green;
                    }
                }
            }
        }
    }

    &.standard-panel--careers-detail {
        min-height: calc(100vh - #{$site-header-height});
        width: 100%;

        .standard-panel__content-container {
            position: relative;
            top: 0;
        }
        // .standard-panel__background-image {
        //     background-position-y: 25%;

        //     @media (max-width: $bp-mobile) {
        //         background-position-y: unset;
        //     }
        // }

        @media (max-width: $bp-desktop) {
            padding: 5%;
            padding-left: 0;
        }
    }
}

.standard-panel {
    &.standard-panel--vedder-thinking-detail {
        .standard-panel__content-container {
            @media (max-width: $bp-desktop) {
                $padding-vertical: 10%;
                padding-bottom: $padding-vertical;
                padding-top: $padding-vertical;
            }
        }
        .standard-panel__primary-heading {
            span:nth-of-type(1) {
                font-size: 1.5rem;
            }
            span:nth-of-type(2) {
                display: inline-block;
                margin-top: 0.1rem;
                font-size: 2.5rem;
                line-height: 1.1;
            }
        }
    }
}

// Microsite Unbranded pages
.standard-panel {
    &.standard-panel--unbranded-microsite-page {
        margin-right: auto;
        padding-bottom: 5%;
        padding-top: 5%;
        width: 95vw;
        @media (max-width: $bp-desktop) {
            padding: 0;
            width: 100%;
        }
        .standard-panel__background-image {
            background-size: 100%;
            background-color: rgba($color-brand-blue, 0.32);
        }
        .standard-panel__decoration-container {
            height: 100vh;
        }
        .standard-panel__decoration-container,
        .standard-panel__content-container {
            width: 50%;
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
        .standard-panel__introduction {
            text-align: left;
        }
        .standard-panel__content-container {
            left: 0;
            margin-left: 0;
            padding-left: 5%;
            position: absolute;
            text-align: left;
            //top: 5rem;
            width: 30%;
            @media (max-width: $bp-desktop) {
                margin-top: 0;
                position: relative;
                top: 0;
                width: 95%;
            }
        }
        .detail-tabs {
            width: 50%;
            
            @media (max-width: $bp-desktop) {
                width: 100%;
            }
        }
    }
}

// Site Search Results
.standard-panel {
    &.standard-panel--site-search-results {
        .standard-search-field__flex-height-protector {
            width: 100%;
        }
        table.gsc-search-box {
            td.gsc-search-button,
            td.gsc-clear-button {
                display: none;
                width: 0px;
            }
            td.gsc-input input {
                background: none !important;
                padding: 0px !important;
                border-top: none !important;
                border-left: none !important;
                border-bottom: 1px solid $color-white;
                border-right: none !important;
                width: 100% !important;
                font-size: 1.2rem;
            }
        }
        .standard-search-results__results {
            .gsc-control-cse {
                font-family: inherit !important;
                background: none !important;
                border: none !important;
                padding: 0px !important;
                width: 100% !important;
            }
            .gsc-result-info {
                color: $color-white !important;
            }
            .gsc-orderby-label {
                color: $color-white !important;
            }
            .gsc-control-cse .gsc-option-menu-container {
                color: $color-white !important;
            }
            .gsc-selected-option-container {
                color: $color-white !important;
            }
            .gsc-table-result {
                color: $color-white;
                .gs-title {
                    a {
                        color: $color-brand-green;
                        &:hover {
                            color: shade($color-brand-green);
                        }
                        b {
                            color: shade($color-brand-green);
                        }
                    }
                }
                .gs-snippet {
                    color: $color-white;
                }
                .gsc-url-bottom .gs-visibleUrl {
                    color: $color-white;
                }
            }
            .gsc-result {
                border-left: none;
            }
        }
    }
}