.detail-tab {
    $transition-duration: 0.5s;

    height: 0;
    opacity: 0;
    overflow-y: hidden;
    transform: translateX(-20%);
    visibility: hidden;

    &.detail-tab--is-selected {
        @include transition(opacity $transition-duration, position 0s $transition-duration, transform $transition-duration, visibility $transition-duration);

        height: auto;
        opacity: 1;
        position: static;
        transform: none;
        visibility: visible;
        max-width: 100%;
    }
}

.detail-tab__generic-container {
    > h2,
    > ul h2 {
        font-size: 1.125rem;
        color: $color-mirage;
    }

    > h2 {
        line-height: 1.2;
        margin-top: 2rem;

        + p {
            margin-top: 0;
        }
    }

    a {
        @include transition(color);
        $color: $color-woodsmoke;
        color: $color;
        font-weight: 500;
        &:hover {
            color: $color-brand-orange;
        }

        .layout__section & {
            color: $color-brand-orange;
        }
    }

    a.icon-envelope, a.icon-vcard {
        @include transition(color);
        $color: $color-alto;
        color: $color;
        cursor: pointer;
        
        &:hover {
            color: $color-brand-orange;
        }
    }

    > p {
        color: $color-woodsmoke;
        line-height: 1.8;

        &:last-of-type {
            font-size: 0.9375rem;
            line-height: 1.8;
            margin-bottom: 0;
        }
        
        @include richtextlink(500);
    }

    li {
        margin-top: 1rem;
        p {
            &:nth-of-type(1) {
                color: $color-woodsmoke;
                line-height: 1;
                margin-bottom: 0;
                margin-top: 0.5rem;
            }

            &:nth-of-type(2) {
                color: $color-corduroy;
                margin-bottom: 2rem;
                margin-top: 0;
            }
        }
    }

    > ul {
        margin-bottom: 2.5rem;
    }

    iframe {
        border-width: 0;
        overflow: hidden;
    }

    img {
        max-width: 100%;
        width: auto;
        height: auto;

         + blockquote {
            color: $color-hippie-green;
            font-size: 0.9375rem;
            line-height: 1.4;
        }
    }

    iframe {
        border-width: 0;
        width: 100%;
    }

    &.detail-tab__services-container {
        h2 {
            font-weight: 800;
        }

        a {
            @include transition(color);
            $color: $color-woodsmoke;
            color: $color;

            &:hover {
                color: $color-brand-orange;
            }
        }
    }
}

.detail-tab__richtext-container {
    @include richtextlist();
    @include richtextlink(500);
}