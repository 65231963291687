.popup {

    .popup__decoration-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        
        .popup__overlay {
            background-color: rgba(66, 66, 66, 0.5);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        .giant-v::before {
            display:none;
        }
    }

    .popup__content-container {
        position: absolute;
        left: 0;
        top: 0;
        margin: 2%;
        z-index: 5;

        color: $color-white;

        .title {

        }

        .description {

        }

        .buttons {
            margin: 5%;

            .button {
                $color: $color-white;

                @include transition(color);

                color: $color;
                display: block;
                font-size: 2.25rem;
                font-weight: 300;
                margin-left: auto;
                text-align: right;

                &:hover {
                    color: shade($color);
                }
            }

            .submit {
                float: left;
            }

            .clear {
                float: right;
            }
        }
    }
}