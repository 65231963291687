.accordion__detail {
    @include transition(height 0.3s, opacity 0.3s);

    height: 0;
    overflow: hidden;

    a {
        @include transition(color);

        $color: $color-brand-orange;

        color: $color;

        &:hover {
            color: shade($color);
        }
    }

    p {
        color: $color-woodsmoke;
        font-size: 1.0625rem;
    }

    li {
        color: $color-woodsmoke;

        &:not(:first-of-type) {
            margin-top: 1rem;
        }
    }
}

.accordion__navigation {
    @include transition(height 0.3s, opacity 0.3s);

    height: 0;
    overflow: hidden;

    a {
        @include transition(color);
        $color: $color-white;
        color: $color;

        &:hover {
            color: shade($color);
        }
    }

    li {
        &:not(:first-of-type) {
            margin-top: 1rem;
        }
    }

    ul {
        margin-left: 5%;
    }
}

// https://bugzilla.mozilla.org/show_bug.cgi?id=984869
.accordion__flex-container {
    align-items: center;
    display: flex;
}

.accordion__summary-icon {
    $margin-horizontal: 0.5rem;

    @include circular-background($height: 2.5rem);

    color: $color-brand-orange;
    font-size: 1.2rem;
    margin-left: $margin-horizontal;
    cursor: pointer;

    @media (max-width: $bp-desktop) {
        margin-left: 0;
        margin-right: $margin-horizontal;
    }
}

.accordion__navigation-icon {
    $margin-horizontal: 0.5rem;

    margin-left: $margin-horizontal;

    @media (max-width: $bp-desktop) {
        margin-left: 0;
        margin-right: $margin-horizontal;
    }
}

.accordion__summary-button {
    &:hover {
        .accordion__summary-icon {
            transform: scale($site-icon-hover-scale);
        }
    }
}
.accordion__summary-button-opportunities {
    width: 100%;
    &:hover {
        .accordion__summary-icon {
            transform: scale($site-icon-hover-scale);
        }
    }
}
.accordion__summary-icon--js {
    @include transition(transform);
    color: $color-brand-orange;
    &:hover {
        transform: scale($site-icon-hover-scale);
        cursor: pointer;
    }
}

.accordion__navigation-button {

}

.accordion {
    $padding-horizontal: 0.25rem;

    margin-bottom: 0.5rem;
    padding-right: $padding-horizontal;

    @media (max-width: $bp-desktop) {
        padding-left: $padding-horizontal;
    }

    &__careers-detail {

        .accordion__flex-container {
            padding-bottom: 1rem;
            width: 100%;
            .accordion__summary-icon {
                $icon-size: 3rem;
                margin-left: 0;
                margin-right: 1.5rem;
                height: $icon-size;
                width: $icon-size;
                font-size: 1.7rem;
            }
            .accordion__summary-text {
                font-size: 1.5rem;
                text-align: left;
            }
        }
    }
}

.accordion__summary-text {
    color: $color-brand-orange;
    font-size: 1.1875rem;
    font-weight: 700;

    @media (max-width: $bp-desktop) {
        order: 1;
    }
}

.accordion__navigation-text {
    color: $color-white;

    &.accordion__navigation-text--is-active {
        font-weight: 800;
    }
}

// Alt
.accordion {
    &.accordion--alt {
        .accordion__flex-container {
            display: block;
        }

        .accordion__summary-icon {
            margin-right: 0;
            height: auto;
            width: auto;
        }

        .accordion__summary-text {}
    }
}

// Services Landing
.accordion {
    &.accordion--services-landing {
        .accordion__flex-container {
            text-align: left;
        }

        .accordion__summary-icon {
            background-color: transparent;
            color: $color-brand-orange;
            font-size: 0.9rem;
            font-weight: 600;
            margin-left: 0;
        }

        .accordion__summary-text {
            color: $color-white;
            font-size: 1.0625rem;
            font-weight: 400;
            order: 0;
        }

        .accordion__detail {
            $color: $color-alto;

            color: $color;

            a {
                color: $color;

                &:hover {
                    color: $color-brand-orange;
                }
            }

            li {
                padding-left: 0.5rem;

                &:not(:first-of-type) {
                    margin-top: 1rem;
                }
            }
        }
    }
}
