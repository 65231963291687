.vedder-thinking-detail-tab__overview-container {
    p {
        &:first-of-type {
            margin-top: 2rem;
        }
    }

    img {
        margin-bottom: 1rem;
        max-width: 100%;
        width: auto;
        height: auto;
    }
}

.vedder-thinking-detail-tab__event-overview-date-container {
    h2 {
        color: $color-mirage;
        font-size: 1.125rem;
        margin-bottom: 0.25rem;

        &:not(:first-of-type) {
            margin-top: 1rem;
        }

        & + p {
            margin-top: 0.4rem;
        }
    }

    p {
        margin-bottom: 0;
        margin-top: 0.25rem;
    }

    .reader-view {
        margin-bottom: 2rem;
    }
}

.vedder-thinking-detail-tab__event-overview-detail-container {
    line-height: 1.9;
    margin-top: 3rem;
}

.vedder-thinking-detail-tab__event-overview-agenda-container {
    color: $color-woodsmoke;
    margin-top: 2rem;

    h2 {
        font-size: 0.9375rem;
        margin-bottom: 0.4rem;
    }

    li {
        font-weight: 300;
        margin-bottom: 1rem;
    }
}

.vedder-thinking-detail-tab__event-overview-files-container {
    @include transition(color);

    $color: $color-brand-orange;

    font-weight: 700;

    li {
        color: $color;

        &:hover {
            color: shade($color);
        }
    }
}

.vedder-thinking-detail-tab__overview-container {
    a {
        @include transition(color);
        $color: $color-brand-orange;
        display: inline-block;
        color: $color;
        font-weight: 400;
    
        &:hover {
            color: shade($color);
        }
    }
}