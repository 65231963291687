$bp-desktop-vedder-thinking: 75rem; // 1200 px

.vedder-thinking-nav {
    margin-bottom: 2rem;
    position: relative;
}

.vedder-thinking-nav__link-list {
    $border-vertical: 1px solid $color-quill-gray;

    align-items: center;
    border-bottom: $border-vertical;
    border-top: $border-vertical;
    color: $color-bombay;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.75rem;
    font-weight: 700;
    justify-content: space-between;
    position: relative;

    @media (max-width: $bp-desktop-vedder-thinking) {
        $padding-vertical: 0.6rem;

        font-size: 1.25rem;
        justify-content: center;
        line-height: 1.5;
        padding-bottom: $padding-vertical;
        padding-top: $padding-vertical;
    }
}

.vedder-thinking-nav__link-list-item {
    $margin-horizontal: 0.75rem;
    $padding-vertical: 0.65rem;

    padding-bottom: $padding-vertical;
    padding-top: $padding-vertical;

    &:not(:first-of-type) {
        margin-left: $margin-horizontal;
    }

    &:not(:last-of-type) {
        margin-right: $margin-horizontal;
    }

    @media (max-width: $bp-desktop-vedder-thinking) {
        $padding-vertical: 0;

        padding-bottom: $padding-vertical;
        padding-top: $padding-vertical;
    }

    &.vedder-thinking-nav__link-list-item--search {
        margin-left: 0;

        @media (max-width: $bp-desktop-vedder-thinking) {
            text-align: center;
            width: 100%;
        }
    }
}

.vedder-thinking-nav__link {
    @include transition(color);

    &.vedder-thinking-nav__link--is-active,
    &:hover {
        color: $color-brand-orange;
    }
}

.vedder-thinking-nav__search-button-text {
    display: block;
    margin-right: 0.25rem;

    @-moz-document url-prefix()
    {
        float: left;
    }

}

.vedder-thinking-nav__search-button {
    $color: $color-brand-orange;
    $icon-height: 1.875rem;

    @include transition(color);

    align-items: center;
    color: $color;
    display: inline-flex;
    font-size: 1.1875rem;
    position: relative;
    z-index: 2;

    @-moz-document url-prefix()
    {
        vertical-align: middle;
    }

    @media (max-width: $bp-desktop-vedder-thinking) {
        margin-top: 0.5rem;

        [class*="icon"] {
            display: none;
        }
    }

    &:hover {
        color: shade($color);
    }

    &-icon {
        padding-left: 1rem;

        &::before {
            @include icon($icon__plus);
            color: $color-brand-orange;
        }
    }

    &.vedder-thinking-nav__search-button--is-active {
        color: $color-brand-blue;

        @media (max-width: $bp-desktop-vedder-thinking) {
            color: $color-brand-green;
        }

        .vedder-thinking-nav__search-button-icon {
            &::before {
                @include icon($icon__minus);
                color: $color-brand-orange;
            }
        }
    }
}
