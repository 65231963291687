.headings {
    font-weight: 400;

    &__h2 {
        &--spaced {
            margin-bottom: 20px;
        }
    }

    @media (min-width: $bp-mobile) {
        &__h2 {
            &--spaced {
                margin-bottom: 36px;
            }
        }
    }
}
