.site-footer {
    align-items: flex-end;
    color: $color-corduroy;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 7%;
    text-transform: uppercase;
    z-index: 6;

    @media (max-width: $bp-mobile) {
        align-items: flex-start;
        flex-direction: column;
        padding: 1.5rem 7%;
    }
}

.site-footer__social-icon {
    $color: $color-alto;

    @include transition(color);

    color: $color;
    font-size: 2.5rem;
    line-height: 0.9;
    cursor: pointer;

    @media (max-width: $bp-mobile) {
        font-size: 2rem;
    }

    &:hover {
        color: $color-brand-orange;
    }
}

.site-footer__social-icon-list {
    margin-right: 2rem;
}

.site-footer__social-icon-list,
.site-footer__site-link-list {
    display: flex;
}

.site-footer__site-link-list {
    flex-wrap: wrap;
}

.site-footer__social-icon-list-item {
    display: inline-block;
    margin-right: 1.9375rem;

    @media (max-width: $bp-mobile) {
        margin-right: 0.75rem;
    }
}

.site-footer__site-link-list-item {
    display: inline-block;

    &:not(:last-child) {
        &::after {
            content: " | ";
            white-space: pre;
        }
    }
}

.site-footer__site-link {
    &:hover {
        color: $color-brand-orange;
    }
}

.site-footer__attorney-advertising,
.site-footer__copyright,
.site-footer__site-link {
    font-size: 1rem;

    @media (max-width: $bp-mobile) {
        font-size: 0.85rem;
    }
}

.site-footer__link-copyright-container {
    @media (max-width: $bp-mobile) {
        margin-top: 0.75rem;
    }
}

.site-footer__regulationtext{
    color: $color-corduroy;
    font-size:.8rem;
    font-weight:400;
    text-transform:none;
}
