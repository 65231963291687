.arrow-button {
    $background-height-large: 2.5rem;
    $background-height-small: 1.5625rem;
    $small-button-icon-color: $color-bombay;

    align-items: center;
    color: $color-alto;
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;

    &::after {
        @include circular-background($height: $background-height-large);

        @include transition(transform 0.6s);

        color: $color-alto;
        content: "\25bc\fe0e";
        display: inline-block;
        font-family: sans-serif;
        font-size: 1.5rem;
        margin-left: 0.65rem;
    }

    &.arrow-button--plain {
        &::after {
            visibility: hidden;
        }
    }

    &.arrow-button--small {
        font-size: 0.8125rem;
        font-weight: 400;

        &::after {
            @include circular-background($background-color: $color-pale-sky, $height: $background-height-small);

            color: $small-button-icon-color;
            font-size: 0.85rem;
        }
    }

    &.arrow-button--is-active {
        $active-transform: rotatex(180deg);
        $background-color: $color-brand-orange;

        @media (max-width: $bp-desktop) {
            color: $color-white;
        }

        &:hover {
            &::after {
                transform: $active-transform scale($site-icon-hover-scale);
            }
        }

        &::after {
            @include circular-background($background-color: $background-color, $height: $background-height-large);

            color: $color-white;
            transform: $active-transform;
        }

        &.arrow-button--small {
            color: $color-white;

            &::after {
                @include circular-background($background-color: $background-color, $height: $background-height-small);

                color: $color-white;
            }
        }
    }
}

.search-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    @include transition(transform);

    &:hover {
        transform: translateY(-50%) scale($site-icon-hover-scale);
    }

    &::before {
        @include icon($icon__loupe);
    }
}

.down-arrow-button.down-arrow-button.down-arrow-button {
    @include transition(background-color, border, transform);

    &::before {

        @include circular-background($background-color: $color-brand-orange, $hover: false);
        border: 1px solid $color-brand-orange;
    }

    &:hover {
        color: $color-alto;
        &::before {
            background-color: $color-arrow-blue;
            border: 1px solid $color-alto;
        }
    }
}

.careers-open-positions-btn.careers-open-positions-btn {
    @include transition(background-color, color);
    display:inline-block;
    width: auto;
    color: white;
    background-color: $color-hoki;
    padding: 0.7rem 2rem;
    font-size: $text-large;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        color: white;
        background-color: shade($color-hoki);
    }

    @media (max-width: $bp-mobile) {
        font-size: 1rem;
        text-align: center;
    }
}