.block-with-text {
    overflow: hidden;
    position: relative;
    line-height: 1.5em;
    text-align: justify;
    margin-right: 0em;
    padding-right: 1em;
}

.block-with-text:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
}
