.professional-card {
    background-color: $color-another-blue;
    color: $color-white;
    font-weight: 300;
    max-width: 400px;

    &__image-link {
        width: 100%;
        padding-bottom: 88%;
        background: no-repeat center;
        background-size: cover;
        display: block;
    }
    &__body {
        padding: 16px;
        min-height: 250px;
    }
    &__name {
        margin-bottom: 18px;
    }
    &__name-link {
        font-size: 22px;
        font-style: italic;
    }
    &__name-position {
        font-size: 18px;
    }
    &__info {
        margin-top: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    &__info-link {
        

        &--email,
        &--vcard {
            &:before {
                width: 35px;
                display: inline-block;
                @include iconStyle()
            }
        }
        &--email {
            &:before {
                box-sizing: border-box;
                padding-left: 4px;
                content: $icon__email-icon;
            }
        }

        &--vcard {
            &:before {
                
                content: $icon__vcard-icon;
            }
        }
        
    }

    @media (min-width: $bp-mobile) {

    }
    
    @media (min-width: $bp-tablet) {

    }
}