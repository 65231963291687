$giant-v-stroke-opacity: 0.32;
$giant-v-careers-detail-opacity: 0.6;
$giant-v-skew: 21.5deg;

.giant-v {
    height: 100%;

    &.giant-v--is-on-left {
        &::before {
            right: 100%;
            transform: skew($giant-v-skew);

            @media (max-width: $bp-desktop) {
                right: 50vh;
            }
        }

        .giant-v__stroke {
            @media (max-width: $bp-desktop) {
                left: 41%;
            }
        }

        .giant-v__stroke--left {
            transform-origin: 0 0;

            @media (max-width: $bp-desktop) {
                transform-origin: 0 100%;
            }
        }
    }

    &.giant-v--is-on-right {
        &::before {
            left: 100%;
            transform: skew(-$giant-v-skew);
        }

        .giant-v__stroke {
            right: 0;
        }

        .giant-v__stroke--left {
            transform-origin: 0 200%;

            @media (max-width: $bp-desktop) {
                transform-origin: 0 150%;
            }
        }

        .giant-v__stroke--right {
            transform-origin: 0 0;

            @media (max-width: $bp-desktop) {
                transform-origin: 0 50%;
            }
        }
    }

    &.giant-v--is-only {
        .giant-v__stroke {
            width: 27vw;

            @media (max-width: $bp-desktop) {
                width: 60vw;
            }
        }
    }

    &::before {
        background-color: $color-white;
        content: "";
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        transform-origin: 0 0;
        width: 100vw;
        z-index: 3;

        @media (max-width: $bp-desktop) {
            display: none;
        }
    }
}

.giant-v__scroll-indicator {
    $translate-x: -20%;

    @include transition(background-color, color);
    // @include circular-background($background-color: $color-alto, $height: 2.8125rem, $hover: false);

    color: $color-white;
    bottom: 0.75rem;
    font-size: 1.5rem;
    opacity: 0.6;
    position: absolute;
    left: 50%;
    transform: skew(-$giant-v-skew) translateX($translate-x);
    z-index: 10;
    cursor: pointer;

    @media (max-width: $bp-desktop) {
        display: none;
    }

    &:hover {
        transform: skew(-$giant-v-skew) translateX($translate-x);
    }
}

.giant-v__stroke {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 22.6vw;

    @media (max-width: $bp-desktop) {
        width: 30vw;
    }

    @media (max-width: $bp-desktop) {
        right: 25%;
        width: 65vw;
    }
}

.giant-v__stroke--left {
    background-color: rgba($color-brand-blue, $giant-v-stroke-opacity);
    text-align: center;
    transform: skew($giant-v-skew);
    z-index: 1;

    @media (max-width: $bp-desktop) {
        transform: skew($giant-v-skew);
    }
}

.giant-v__stroke--right {
    background-color: rgba($color-brand-green, $giant-v-stroke-opacity);
    transform: skew(-$giant-v-skew);
    transform-origin: 0 200%;

    @media (max-width: $bp-desktop) {
        transform: skew(-$giant-v-skew);
        transform-origin: 0 100%;
    }

    &.giant-v__stroke--green {
        background-color: $color-brand-green;
    }
}

// Detail pages
.giant-v {
    &.giant-v--detail-page,
    &.giant-v--general-content-page {
        .giant-v__stroke {
            transform-origin: 0 0;
        }

        .giant-v__stroke--left {
            right: 110%;
        }
        .giant-v__stroke--right {
            @media (max-width: $bp-desktop) {
                left: 20%;
                right: auto;
            }
        }
    }

    &--careers-detail {
        .giant-v__stroke {
            transform-origin: 0 0;

            &--left {
                background-color: rgba($color-brand-blue, $giant-v-careers-detail-opacity);
                right: 55%;
            }

            &--right {
                background-color: rgba($color-brand-green, $giant-v-careers-detail-opacity);
                right: 0;
                @media (max-width: $bp-desktop) {
                    transform: skew(-$giant-v-skew);
                    transform-origin: 0 100%;
                }
            }
        }
    }
}

// Professionals Detail
.giant-v {
    &.giant-v--professionals-detail {
        .giant-v__stroke {
            width: 25vw;
        }
    }
}

// Professionals Landing, Services Landing, Site Search Results
.giant-v {
    &.giant-v--professionals-landing,
    &.giant-v--services-landing,
    &.giant-v--site-search-results {
        .giant-v__stroke {
            transform-origin: 0 0;
        }

        .giant-v__stroke--left {
            right: 100%;
        }
    }
}
