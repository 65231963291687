.services-search-grid__list {
    font-size: 1.0625rem;
    height: 100%;
    padding: 2.5rem;

    @media (max-width: $bp-desktop) {
        padding: 1.5rem;
    }

    a {
        $color: $color-white;

        @include transition(color);

        color: $color;
        font-size: 1.25rem;
        font-weight: 600;

        @media (max-width: $bp-desktop) {
            font-size: 1rem;
        }

        &:hover {
            color: shade($color);
        }
    }

    li {
        margin-bottom: 1.5rem;

        @media (max-width: $bp-desktop) {
            margin-bottom: 1rem;
        }
    }

    &.services-search-grid__list.services-search-grid__list.services-search-grid__list {
        @media (max-width: $bp-desktop) {
            border-right-width: 0;
            height: auto;
        }
    }
}

.services-search-grid {
    position: relative;
}

.services-search-grid__flex-container {
    display: flex;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $bp-desktop) {
        flex-wrap: wrap;
    }

    > * {
        width: 100%;
    }
}

.services-search-grid__list-container {
    $industries-grid-list-border: 2px dotted $color-white;

    background-color: rgba($color-mirage, 0.87);
    overflow: hidden; // Prevents .industries-grid__list border from overflowing
    &:not(:last-child) {
        .services-search-grid__list {
            border-right: $industries-grid-list-border;

            @media (max-width: $bp-desktop) {
                border-bottom: $industries-grid-list-border;
            }
        }
    }
}

.services-search-grid__no-results-container {
    margin-left: auto;
    margin-right: auto;

    > * {
        @media (max-width: $bp-desktop) {
            width: 500%;
        }
    }
}

.services-search-grid__list-container-no-results {
    $industries-grid-list-border: 2px dotted $color-white;

    background-color: rgba($color-mirage, 0.87);
    overflow: hidden;

    padding: 2.5rem;
}
