$bp-mobile-small: 23.375rem;
$bp-mobile: 47.9375rem;
$bp-tablet: 52.85714286rem;
$bp-desktop: 63.9375rem;
$bp-desktop-vedder-thinking: 75rem;

.mobile-only {
	@media (min-width: $bp-mobile) {
			display: none !important;
	}
}

.tablet-only {
	@media (max-width: $bp-mobile) {
		display: none !important;
	}

	@media (min-width: $bp-desktop) {
		display: none !important;
	}
}

.desktop-only {
	@media (max-width: $bp-desktop) {
			display: none !important;
	}
}