.vedder-thinking-header {
    position: relative;
 
    .h1Div{
        display:inline-block;
    }

    h1 {  
        float: right;
        cursor: pointer;
        text-align: right;
        display: block;

        span:nth-of-type(1) {
            $font-size: 3.75rem;
            color: $color-brand-green;
            display: block;
            font-size: 3.75rem;
            margin-bottom: -2.75rem;
           
            @media (max-width: $bp-desktop) {
                font-size: 2.5rem;
                margin-bottom: -1.5rem;
            }
        }
        span:nth-of-type(2) {
            color: $color-brand-blue;
            font-size: 5.25rem;
            @media (max-width: $bp-desktop) {
                font-size: 3rem;
            }
        }
        img {          
            height: 5.2rem;
            @media (max-width: $bp-desktop) {
                height: 2.2rem;
            }
        }
    }

    &__subscribe-link {
        $color: $color-brand-lighter-blue;

        @include transition(background-color);

        background-color: $color;
        color: $color-white;
        display: inline-block;
        font-size: 1.1875rem;
        font-weight: 700;
        position: absolute;
        bottom: 0;
        padding: 0.5rem 5rem;
        margin-bottom: 0.5rem;

        @media (max-width: $bp-desktop-vedder-thinking) {
            font-size: 0.9rem;
        }

        @media (max-width: $bp-mobile) {
            padding: 0.5rem 1rem;
        }

        @media (max-width: 22.25rem) {
            padding: 0;
            color: $color;
            background-color: transparent;
        }

        &:hover {
            background-color: shade($color);
        }
    }
    @include clearfix();
}
