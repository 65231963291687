// Brand
$color-brand-blue: #09305a;
$color-brand-green: #9caa00;
$color-brand-orange: #e26f17;

$color-brand-lighter-blue: #6d9dbe;
$color-brand-hover-blue: #08395a;
$color-brand-dark-blue: #091a3c;

// Common
$color-alto: #c8c8c8;
$color-big-stone: #335875;
$color-black-pearl: #091a3c;
// $color-black-pearl: #343e44;
$color-black-squeeze: #e5e5e0;
$color-black: #000;
$color-blue-charcoal: #262d2e;
$color-bombay: #aeb2ae;
$color-catskill-white: #eee;
$color-chicago: #645c59;
$color-cinder: #0f1018;
$color-corduroy: #748675;
$color-dark-slate: #495854;
$color-gainsboro: #dcdcdc;
$color-hippie-green: #9baa00;
$color-hoki: $color-brand-lighter-blue;
$color-horizon: #55acee;
$color-licorice: #2d4f72;
$color-mirage: #08305a;
$color-outer-space: #2d4c69;
$color-oxford-blue: #266091;
$color-pale-sky: #778895;
$color-plantation: #586e64;
$color-pumice: #c0c7c2;
$color-quill-gray: #cacbc6;
$color-sage: #bcc265;
$color-sand-dune: #857366;
$color-slate-gray: #758894;
$color-white: #fff;
$color-woodsmoke: #424242;
$color-gray: #b3b3b5;
$color-arrow-blue: #2e506d;
$color-careers-grey: #b3b3b5;
$color-another-blue: #276092;

// Miscellaneous
$color-box-shadow: #909090;
$color-image-overlay: rgba($color-woodsmoke, 0.4);
$color-light-gray: #a4a4a4;
$color-off-white: #e9e9e9;