.standard-contactus-field__input-label input,
.standard-contactus-field__textarea-label input,
.standard-contactus-field__checkbox-label input,
.standard-contactus-field__select-label select {
    box-sizing: border-box;
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0.4rem;
    padding-bottom: 0.25rem;
    transform: translateY(-50%);
    width: 100%;
}

.standard-contactus-field__select-label {

    .standard-contactus-field__select-bottom-border {
        width: unquote("calc(100% - 112px)");
        position: absolute;
        border-bottom: 1px dotted $color-woodsmoke;
        left: 87px;

       
         @media (max-width: $bp-mobile) {
            left: 71px;
            width: unquote("calc(100% - 99px)");
        }
    }

    &::after {
        content: "▶";
        display: inline-block;
        font-family: sans-serif;
        font-size: 1.5rem;
        margin-left: 0.3rem;
        transform: rotate(90deg);
        z-index: 9;
        margin-left: -2rem;
    }
}

.standard-contactus-field__input-label,
.standard-contactus-field__textarea-label,
.standard-contactus-field__checkbox-label,
.standard-contactus-field__select-label {
    align-items: center;
    display: flex;
    position: relative;
    margin-bottom: 0.75rem;

    input {
        border-bottom: 1px solid $color-woodsmoke;
    }

    select {
        border-bottom: none;
        z-index: 10;
    }

    span {
        color: $color-white;
    }

    textarea {
        width: 100%;
        height: 150px;
        border: 1px solid $color-woodsmoke;
        resize: none;
        margin-left: 0.4rem;
    }
}

.standard-contactus-field__label {
    display: inline-block;
}

.standard-contactus-field__textarea-label {
    align-items: flex-start;
}

.standard-contactus-field__input-label {
    .g-recaptcha {
        margin: auto;
    }
}

@mixin standard-contactus-field-label-text($background-color) {
    @include circular-background($background-color: $background-color, $height: 5rem, $hover: false);

    font-family: $font-family-primary--condensed;
    font-size: 1.1875rem;

    @media (max-width: $bp-mobile) {
        @include circular-background($background-color: $background-color, $height: 4rem, $hover: false);
        font-size: 0.9rem;
    }
}

.standard-contactus-field__label-text--firstname {
    @include standard-contactus-field-label-text($background-color: $color-brand-orange);
}

.standard-contactus-field__label-text--lastname {
    @include standard-contactus-field-label-text($background-color: $color-corduroy);
}

.standard-contactus-field__label-text--title {
    @include standard-contactus-field-label-text($background-color: $color-pale-sky);
}

.standard-contactus-field__label-text--company {
    @include standard-contactus-field-label-text($background-color: $color-hoki);
}

.standard-contactus-field__label-text--email {
    @include standard-contactus-field-label-text($background-color: $color-sage);
}

.standard-contactus-field__label-text--subject {
    @include standard-contactus-field-label-text($background-color: $color-hippie-green);
}

.standard-contactus-field__label-text--comments {
    @include standard-contactus-field-label-text($background-color: $color-mirage);
}

.standard-contactus-field__label-text--terms {
    @include standard-contactus-field-label-text($background-color: $color-hoki);
}

.standard-contactus-field__label-text--captcha {
    @include standard-contactus-field-label-text($background-color: $color-chicago);
    visibility: hidden;
}