.site-header {
    height: $site-header-height;
    overflow: visible;

    @media (max-width: $bp-mobile) {
        height: $site-header-height-mobile;
    }

    &,
    button {
        color: $color-alto;
        font-weight: 300;
    }
}

// https://bugzilla.mozilla.org/show_bug.cgi?id=984869
.site-header__flex-container {
    align-items: center;
    display: flex;
}

.site-header__fixed-container {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 23;
}

.site-header__toolbar-icon {
    @include transition(transform);

    font-size: 2.8rem;

    // @media (hover: hover) {
    //     &:hover {
    //         transform: scale($site-icon-hover-scale);
    //     }
    // }
}

.site-header__toolbar {
    align-items: center;
    background-color: $color-white;
    display: flex;
    height: $site-header-height;
    left: 0;
    position: relative;
    width: 100%;
    z-index: 6;

    @media (max-width: $bp-mobile) {
        height: $site-header-height-mobile;
        z-index: 6;

        &::before {
            background-color: $color-white;
            bottom: 100%;
            content: "";
            height: 50rem;
            position: absolute;
            width: 100%;
            z-index: -1;
        }
    }
}

.site-header__tools {
    $margin-left: 2.0rem;

    display: flex;
    margin-left: auto;
    margin-right: 5%;

    > * {
        align-items: center;
        display: flex;
        margin-left: $margin-left;

        @media (max-width: $bp-mobile) {
            margin-left: $margin-left / 3;
        }

        &.site-header__search-button {
            margin-left: $margin-left * 1.5;

            @media (max-width: $bp-tablet) {
                margin-left: $margin-left / 2;
            }
            @media (max-width: $bp-mobile) {
                margin-left: $margin-left / 3;
            }
        }
    }
}

.site-header__logo {
    display: inline-block;
    margin-left: 8vw;

    @media (max-width: $bp-desktop) {
        margin-left: 0;
    }

    img {
        height: 3.5vw;

        @media (max-width: $bp-mobile) {
            height: 1.5rem;
            margin-left: 1rem;
        }
    }
}

.site-header__drawer-toggle--js.site-header__drawer-toggle--is-active {
    .icon-hamburger, .icon-loupe {
        @extend .icon-close;
        font-size: 1.5rem;
    }

    .icon-share{
        @extend .icon-close;
        font-size: 1.5rem;

        &::before {
            padding-right: 0;
        }
    }

    .icon-loupe {
        @extend %grey-circle-icon;
    }
}

.site-header__menu-button {
    .site-header__toolbar-icon {
        @include transition(color, transform);
    }

    .site-header__toolbar-icon {
        @extend %grey-circle-icon;
    }
}

.site-header__vedder-thinking-anchor {
    @media (max-width: $bp-mobile) {
        display: flex;
    }

    // @media (hover: hover) {
    //     &:hover {
    //         .site-header__toolbar-icon {
    //             color: $color-brand-orange;
    //             transform: rotate(90deg) scale($site-icon-hover-scale);
    //         }
    //     }
    // }

    .site-header__toolbar-icon {
        @include transition(color, transform);

        transform: rotate(90deg);
    }
}

.site-header__toolbar-icon-text {
    color: $color-big-stone;
    font-size: 1.0625rem;
    margin-right: 0.5rem;

    @media (max-width: $bp-mobile) {
        display: none;
    }
}

.site-header__mini-tool-list-item {
    display: inline-block;
    font-size: 1.5rem;
    margin-left: 2rem;
}

.site-header__mini-tool-list {
    display: none;

    &--is-active {
        display: flex;
    }

    @media (max-width: $bp-tablet) {
        display: none;
    }
}

.site-header__mini-tool {
    $color: $color-white;

    @include transition(color);

    color: $color;

    // @media (hover: hover) {
    //     &:hover {
    //         color: shade($color);
    //     }
    // }
}

.site-header__share-tools {
    .icon-share {
        @extend %grey-circle-icon;

        &::before {
            padding-right: 5px;
        }
    }

    // @media (hover: hover) {
    //     &:hover {
    //         .site-header__toolbar-icon {
    //             transform: scale($site-icon-hover-scale);
    //         }
    //     }
    // }

    @media (max-width: $bp-mobile) {
        display: none;
    }
}

// Vedder Thinking Landing
.site-header {
    &.site-header--vedder-thinking-landing {
        .site-header__toolbar {
            border-bottom: 1px solid $color-catskill-white;
        }

        .site-header__vedder-thinking-anchor {
            .site-header__toolbar-icon {
                color: $color-brand-orange;
                transform: rotate(-90deg);
            }
        }
    }
}

@media screen and (hover: hover), (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .site-header__toolbar-icon:hover {
        transform: scale($site-icon-hover-scale);
    }

    .site-header__menu-button {
        &:hover {
            .site-header__toolbar-icon {
                background-color: $color-brand-orange;
                transform: scale($site-icon-hover-scale);
            }
        }
    }

    .site-header__vedder-thinking-anchor {
        &:hover {
            .site-header__toolbar-icon {
                color: $color-brand-orange;
                transform: rotate(90deg) scale($site-icon-hover-scale);
            }
        }
    }

    .site-header__search-button {
        &:hover {
            .site-header__toolbar-icon {
                color: $color-brand-orange;
            }
        }
    }

    .site-header__mini-tool {
        &:hover {
            color: shade($color-white);
        }
    }

    .site-header__share-tools {
        &:hover {
            .site-header__toolbar-icon {
                background-color: $color-brand-orange;
                transform: scale($site-icon-hover-scale);
            }
        }
    }
}