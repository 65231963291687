$services-grid-list-padding: 1.75rem 1.25rem;

.services-grid__list {
    font-size: 1.0625rem;
    height: 100%;
    padding: $services-grid-list-padding;

    &.services-grid__list.services-grid__list.services-grid__list {
        @media (max-width: $bp-desktop) {
            border-width: 0;
        }
    }
}

.services-grid__view-all-button {
    color: $color-white;
    position: absolute;
    right: 1rem;
    top: 1.75rem;
    z-index: 1;
    display:flex;
}

#services-grid__view-all-button--js.services-grid__view-all-button--is-active {
    color: $color-alto;
}

.services-grid {
    position: relative;
}

.services_button_span {
    float:left;
    display: none;

    &.services_button_span--active {
        display: block;
    }
}

.services_arrow_button_span {
    padding-left: 0.2rem;
    line-height: inherit;
    color: $color-brand-orange;
}

.services-grid__flex-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $bp-desktop) {
        flex-wrap: wrap;
    }

    > * {
        width: 100%; 
    }
}

.services-grid__list-heading {
    background-color: rgba($color-oxford-blue, 0.88);
    color: $color-sage;
    font-size: 1.125rem;
    font-weight: 700;
    padding: $services-grid-list-padding;
}

.services-grid__list-container {
    $services-grid-list-border: 2px dotted $color-white;

    overflow: hidden; // Prevents .services-grid__list border from overflowing
    background-color: rgba($color-licorice, 0.87);

    &:not(:last-child) {
        .services-grid__list {
            border-right: $services-grid-list-border;
        }
    }

    .services-grid__list {
        border-top: $services-grid-list-border;
    }
}
