.standard-contactus-form {
    box-sizing: border-box;
    position: relative;
    width: 95%;

    option {
        color: $color-black; // IE

    }
}

.standard-contactus-form__select-label-text,
.standard-contactus-form__input-label-text {
    font-family: $font-family-primary--condensed;
    font-size: 1.125rem;
    font-weight: 300;

    @media (max-width: $bp-mobile) {
        font-size: 1rem;
    }
}

.standard-contactus-form__submit-button {
    $color: $color-woodsmoke;

    @include transition(color);

    color: $color;
    display: block;
    font-size: 2.25rem;
    font-weight: 300;
    margin-left: auto;
    text-align: right;

    &:hover {
        color: shade($color);
    }

    @media (max-width: $bp-desktop) {
        font-size: 1.5rem;
    }
}

.standard-contactus-form__bottom-button-container {
    align-items: center;
    display: flex;
    margin-top: 1rem;
}

.standard-contactus-form__clear-input-icon {
    @include circular-background($background-color: $color-bombay, $height: 2rem);

    color: $color-woodsmoke;
    font-size: 1.15rem;
    margin-right: 0.25rem;
}

.standard-contactus-form__clear-input-label {
    cursor: pointer;
}

.standard-contactus-form__clear-input-text {
    color: $color-woodsmoke;
    font-size: 1.25rem;
    font-weight: 300;
}

.standard-contactus-form__introduction {
    padding-bottom: 1rem;
}