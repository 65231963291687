@mixin circular-background($background-color: $color-black-squeeze, $border: null, $height: 2.5rem, $hover: true, $transform: null, $transition: null) {
    $site-transition-duration: 0.3s;

    @include transition(transform, $transition);

    align-items: center;
    background-color: $background-color;
    border-radius: 50%;
    display: inline-flex;
    flex-shrink: 0;
    height: $height;
    justify-content: center;
    text-align: center;
    width: $height;

    @if $border {
        border: $border;
    }

    @if $transform {
        transform: $transform;
    }

    @if $hover {
        @media screen and (hover: hover), (-ms-high-contrast: none), (-ms-high-contrast: active) {
            &:hover {
                transform: $transform scale($site-icon-hover-scale);
            }
        }
    }
}

@mixin constant-rotate($duration: 1s) {
    animation: rotate $duration linear infinite forwards;
    animation-play-state: running;

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

}

@mixin transition($transitions...) {
    $unfolded-transitions: ();

    @if length($transitions) == 0 {
        $unfolded-transitions: append($unfolded-transitions, unfold-transition(), comma);
    } @else {
        @each $transition in $transitions {
            $unfolded-transitions: append($unfolded-transitions, unfold-transition($transition), comma);
        }
    }

    transition: $unfolded-transitions;
}

@mixin clearfix() {
    &::after {
        content: '';
        clear: both;
        display: table;
    }
}

@mixin richtextlist() {
    ul {
        color: $color-brand-blue;
        font-size: 0.9375rem;
        font-weight: 300;
    }

    li {
        margin-bottom: 0;
    }

    li:not(:last-child) {
        border-bottom: 1px solid rgba($color-brand-blue, 0.4);
        margin-bottom: .5rem;
        padding-bottom: .1rem;
    }
}

@mixin richtextlink($font-weight) {
    a {
        font-weight: $font-weight;
        color: $color-woodsmoke;
        
        &:hover {
            color: $color-brand-orange;
        }
    }
}
