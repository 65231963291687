.services-detail-tab__overview-container {
    h2 {
        line-height: 1.2;
    }

    p {
        &:first-of-type {
            margin-top: 2rem;
        }
    }
    
    section {
        padding-bottom: 2rem;
    }
}
