.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    z-index: 20;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.5);
}

.overlay .progress {
    position: absolute;
    top: 45%;
    top: calc(50% - 3rem);
    left: 45%;
    left: calc(50% - 3rem);
    margin-right: 50%;

    .icon-loading {
        color: #fff;
        font-size: 6rem;
    }
}