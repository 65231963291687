.standard-search-form {
    background-color: rgba($color-brand-blue, 0.72);
    box-sizing: border-box;
    margin-top: 2.5rem;
    padding: 1rem;
    position: relative;

    option {
        color: $color-black; // IE

    }
}

.standard-search-form__letter-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    legend {
        @extend %visually-hidden;
    }
}

.standard-search-form__letter-filter-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 0;
}

.standard-search-form__letter-filter-list-item {
    font-size: 1rem;
    margin-right: 1.25rem;
    text-transform: uppercase;

    &.inactive{
        color:$color-plantation;
        .standard-search-form__letter-filter-label-text{
            cursor: default;
        }
    }
}

.standard-search-form__letter-filter-checkbox {
    @extend %visually-hidden;
}

.standard-search-form__letter-filter-label-text:hover {
    @include transition(color);

    color: $color-plantation;
}

.standard-search-form__letter-filter-label-text {
    cursor: pointer;
    font-weight: 300;
}

.standard-search-form__select-label-text,
.standard-search-form__input-label-text {
    font-family: $font-family-primary--condensed;
    font-size: 1.125rem;
    font-weight: 300;

    @media (max-width: $bp-mobile) {
        font-size: 1rem;
    }
}

.standard-search-form__non-letter-filters {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    > * {
        break-inside: avoid;
        padding-bottom: 0.5rem;
        width: 50%;

        @media (max-width: $bp-mobile) {
            width: 100%;
        }
    }
}

.standard-search-form__non-letter-filters-full-width {
    display: flex;
    flex-wrap: wrap;

    > * {
        break-inside: avoid;
        margin-bottom: 2.5rem;
        width: 100%;

        @media (max-width: $bp-mobile) {
            width: 100%;
        }
    }
}

.standard-search-form__submit-button {
    $color: $color-white;
    background-color: $color-brand-lighter-blue;

    @include transition(background-color);

    color: $color;
    display: block;
    font-size: 1.25rem;
    padding: 1rem 0;
    font-weight: 300;
    margin-left: auto;
    text-align: center;
    width: 100%;

    &:hover {
        background-color: $color-brand-hover-blue;
    }

    @media (max-width: $bp-desktop) {
        font-size: 1.5rem;
    }
}

.standard-search-form__clear-filters-button {
    $color: $color-white;

    @include transition(color);

    color: $color;
    font-size: 1.25rem;
    font-weight: 300;
    margin-left: auto;

    &:hover {
        color: shade($color);
    }
}

.standard-search-form__team-button {
    bottom: 102.5%;
    color: $color-woodsmoke;
    position: absolute;
    right: 0;

    @media (max-width: $bp-desktop) {
        color: $color-white;
    }
}

.standard-search-form__team-icon {
    @include circular-background($height: 2.5rem, $transform: rotate(90deg));

    &::after {
        font-size: 1.25rem;
    }
}

.standard-search-form__clear-button {
    $color: $color-white;

    @include transition(color);
    color: $color;

    align-items: center;
    display: inline-flex;
    padding-bottom: 0;
    padding-top: 0;

    &:hover {
        color: shade($color);
    }
}

.standard-search-form__bottom-button-container {
    align-items: center;
    display: flex;
    margin-top: 1rem;
}

.standard-search-form__bottom-button-inner-container {
    width: 50%;
}

.standard-search-form__clear-input-icon {
    @include circular-background($background-color: $color-bombay, $height: 2rem);

    color: $color-white;
    font-size: 1.15rem;
    margin-right: 0.25rem;
}

.standard-search-form__clear-input-label {
    cursor: pointer;

    &:hover {
        .standard-search-form__clear-input-text {
            color: shade($color-horizon);
        }

        .standard-search-form__clear-input-icon {
            transform: scale($site-icon-hover-scale);
        }
    }
}

.standard-search-form__clear-input-text {
    color: $color-horizon;
    font-size: 1.25rem;
    font-weight: 300;
}

// Services Landing
.standard-search-form {
    &.standard-search-form--services-landing {
        background-color: transparent;
        top: 0;
        position: absolute;
        right: 0;
        padding: 0;
        margin-top: 0;

        @media (max-width: $bp-desktop) {
            margin-top: 0;
            padding-top: 0;
            position: relative;
        }

        @media (max-width: $bp-mobile) {
            padding-left: 0;
        }

        .standard-search-form__non-letter-filters {
            width: 100%;
            padding-right: 15rem;
            columns: auto auto;

            > * {
                width: 100%;
            }
        }

        .standard-search-field__input-label {
            margin-left: auto;
            width: 100%;

            @media (max-width: $bp-desktop) {
                padding-top: 1rem;
                width: 65%;
            }

            @media (max-width: $bp-mobile) {
                width: 100%;
            }

            input {
                margin-left: 0;
                border-bottom: 1px solid $color-alto;
                color: $color-brand-orange;
                font-size: 1rem;

                @media (max-width: $bp-desktop) {
                    color: $color-white;
                }
            }
        }

        .standard-search-form__submit-button {
            $color: $color-corduroy;

            color: $color;
            font-size: 2rem;
            transform: translateY(-50%);

            &:hover {
                color: shade($color);
            }

            @media (max-width: $bp-desktop) {
                $color: $color-white;

                color: $color;
                font-size: 1.5rem;

                &:hover {
                    color: shade($color);
                }
            }
        }
    }
}

// Vedder Thinking
.standard-search-form {
    &.standard-search-form--vedder-thinking {
        $background-color: rgba($color-black-pearl, 0.95);

        @include transition(opacity, visibility);

        background-color: $background-color;
        opacity: 0;
        padding: 3rem 2.5rem 1rem;
        position: absolute;
        right: 0;
        top: 100%;
        visibility: hidden;
        width: 35%;
        z-index: 1;
        margin-top: 0;

        @media (max-width: $bp-desktop-vedder-thinking) {
            left: 0;
            padding: 1rem 1.5rem;
            top: 100%;
            width: 100%;
        }

        &.standard-search-form--is-active {
            opacity: 1;
            visibility: visible;
        }

        .standard-search-form__submit-button {
            @include transition(background-color);
            font-size: 1.25rem;
            padding: 1rem 0;
            margin: 0 auto;
            background-color: $color-brand-lighter-blue;
            width: 100%;
            text-align: center;

            &:hover {
                background-color: $color-brand-hover-blue;
                color: white;
            }

            @media (max-width: $bp-desktop-vedder-thinking) {
                font-size: 1.5rem;
            }
        }

        .standard-search-form__non-letter-filters,
        .standard-search-form__non-letter-filters-full-width {
            color: $color-white;
            columns: auto 1;
            font-weight: 400;
        }
    }
}

// Professional Landing
.standard-search-form {
    &__administrative-team {
        $background-color: $color-brand-blue;
        $height: 6.5rem;
        
        @include circular-background($background-color: $background-color, $height: $height);
        position: absolute;
        top: 5%;
        color: $color-white;
        font-family: $font-family-primary--condensed;
        font-size: 1.1875rem;
        line-height: 1.1;
        border: 2px solid $background-color;
        margin-bottom: 0.75rem;
        


        &--is-selected {
            @include circular-background($background-color: $color-white, $height: $height);

            color: $background-color !important;
        }

        @media (max-width: $bp-desktop) {
            top: 0;
        }


    }
    &#professional-search-form {
        background-color: $color-brand-dark-blue;
        padding: 2% 5% 5% 10%;

        .standard-search-field__input-label {
            margin-top: 1rem;
        }
    
        .standard-search-form__non-letter-filters {
            margin-top: 0;
            justify-content: space-between;
    
            > * {
                padding-bottom: 0;
                margin-top: 3rem;
                flex-basis: 48%;
            }
    
            .standard-search-field {
                &__select-label {
                    select {
                        height: 100%;
                    }
                }
            }
    
            .standard-search-form__bottom-button-container {
                margin-top: 0;
                padding-top: 0;
                height: 100%;
                align-self: flex-end;
    
                .standard-search-form__submit-button {
                    font-size: 1.25rem;
                    font-weight: 600;
                    width: 100%;
                    height: 100%;
                    background-color: $color-brand-lighter-blue;
                    @include transition(background-color);
                    padding: 1rem 0;
                    text-align: center;
    
                    &:hover {
                        background-color: $color-brand-hover-blue;
                    }
                }
            }
        }

        // .standard-search-form__administrative-team {
        //     $background-color: $color-brand-blue;
        //     $height: 6.5rem;

        //     color: $color-white;
        //     font-family: $font-family-primary--condensed;
        //     font-size: 1.1875rem;
        //     line-height: 1.1;

        //     @media (min-width: $bp-desktop) {
        //         @include circular-background($background-color: $background-color, $height: $height);

        //         border: 2px solid $background-color;
        //         margin-bottom: 0.75rem;

        //         &.detail-tabs-nav__anchor--is-selected {
        //             @include circular-background($background-color: $color-white, $height: $height);

        //             color: $background-color;
        //         }
        //     }
        // }
    }
}

// Site Search
.standard-search-form {
    &__site-search {
        .standard-search-form__non-letter-filters {
            justify-content: flex-end;

            .standard-search-field__select-label {
                margin-top: 1rem;
            }
        }

        .standard-search-form__bottom-button-inner-container {
            width: 100%;
        }
    }
}