.layout {
  &__wrapper {
    padding: 0 32px;

    &--small {
      padding: 0 18px;
    }
  }
  // body Content
  &__content {
    padding: 40px 0;
  }

  &__section {
    .detail-tab__generic-container {
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.8;
    }
    & + & {
      margin-top: 30px;
    }
  }

  &__column-aside {
    margin-top: 30px;
  }

  @media (min-width: $bp-mobile) {
    &__wrapper {
      padding: 0 32px;
    
      &--small {
        padding: 0 32px;
      }
    }

    &__content {
      padding: 50px 0;
    }
  }
  
  @media (min-width: $bp-tablet) {
    &__content {
      padding: 70px 0;
    }
    &__section {
      &+& {
        margin-top: 50px;
      }
    }
    &__wrapper {
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
    }
    &__columns {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: nowrap;
    }
    &__column-aside {
      width: 28%;
      flex-shrink: 0;
      padding-right: 40px;
      box-sizing: border-box;
      margin-top: 0;
    }
    &__column-main {
      flex-grow: 1;
    }
  }
}