.sitemap-grid {
    ul.sitemap-grid--container {
        columns: auto 4;

        @media (max-width: $bp-desktop) {
            columns: auto 3;
        }

        @media (max-width: $bp-tablet) {
            columns: auto 3;
        }

        @media (max-width: $bp-mobile) {
            columns: auto 2;
        }

        @media (max-width: $bp-mobile-small) {
            columns: auto 1;
        }
    }

    ul.sitemap-grid--sub {
        padding-left: 1rem;

        @media (max-width: $bp-desktop) {
            padding-left: 0;
        }
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    li.sitemap-grid--section {
        break-inside: avoid;
        padding-bottom: 2rem;
      }

    h2 {
        font-size: 1.4375rem;

        @media (max-width: $bp-desktop) {
            font-size: 1.2rem;
        }
    }
}