@mixin detail-tabs-tab($background-color) {
    $height: 5.4rem;
    font-family: $font-family-primary--condensed;
    font-size: 1.1875rem;
    line-height: 1.1;

    @media (min-width: $bp-desktop) {
        @include circular-background($background-color: $background-color, $height: 5rem, $hover: false);
        border: 2px solid $background-color;

        &.detail-tabs-nav__anchor--is-selected {
            @include circular-background($background-color: $color-white, $height: 5rem, $hover: false);
            color: $background-color;
        }
    }
}

.detail-tabs-tab-brand-blue {
    @include detail-tabs-tab($background-color: $color-brand-blue);
}

.detail-tabs-tab-brand-green {
    @include detail-tabs-tab($background-color: $color-brand-green);
}

.detail-tabs-tab-brand-orange {
    @include detail-tabs-tab($background-color: $color-brand-orange);
}

.detail-tabs-tab-alto {
    @include detail-tabs-tab($background-color: $color-alto);
}

.detail-tabs-tab-big-stone {
    @include detail-tabs-tab($background-color: $color-big-stone);
}

.detail-tabs-tab-black-pearl {
    @include detail-tabs-tab($background-color: $color-black-pearl);
}

.detail-tabs-tab-black-squeeze {
    @include detail-tabs-tab($background-color: $color-black-squeeze);
}

.detail-tabs-tab-black {
    @include detail-tabs-tab($background-color: $color-black);
}

.detail-tabs-tab-blue-charcoal {
    @include detail-tabs-tab($background-color: $color-blue-charcoal);
}

.detail-tabs-tab-bombay {
    @include detail-tabs-tab($background-color: $color-bombay);
}

.detail-tabs-tab-catskill-white {
    @include detail-tabs-tab($background-color: $color-catskill-white);
}

.detail-tabs-tab-chicago {
    @include detail-tabs-tab($background-color: $color-chicago);
}

.detail-tabs-tab-cinder {
    @include detail-tabs-tab($background-color: $color-cinder);
}

.detail-tabs-tab-corduroy {
    @include detail-tabs-tab($background-color: $color-corduroy);
}

.detail-tabs-tab-dark-slate {
    @include detail-tabs-tab($background-color: $color-dark-slate);
}

.detail-tabs-tab-gainsboro {
    @include detail-tabs-tab($background-color: $color-gainsboro);
}

.detail-tabs-tab-hippie-green {
    @include detail-tabs-tab($background-color: $color-hippie-green);
}

.detail-tabs-tab-hoki {
    @include detail-tabs-tab($background-color: $color-hoki);
}

.detail-tabs-tab-horizon {
    @include detail-tabs-tab($background-color: $color-horizon);
}

.detail-tabs-tab-licorice {
    @include detail-tabs-tab($background-color: $color-licorice);
}

.detail-tabs-tab-mirage {
    @include detail-tabs-tab($background-color: $color-mirage);
}

.detail-tabs-tab-outer-space {
    @include detail-tabs-tab($background-color: $color-outer-space);
}

.detail-tabs-tab-oxford-blue {
    @include detail-tabs-tab($background-color: $color-oxford-blue);
}

.detail-tabs-tab-pale-sky {
    @include detail-tabs-tab($background-color: $color-pale-sky);
}

.detail-tabs-tab-plantation {
    @include detail-tabs-tab($background-color: $color-plantation);
}

.detail-tabs-tab-pumice {
    @include detail-tabs-tab($background-color: $color-pumice);
}

.detail-tabs-tab-quill-gray {
    @include detail-tabs-tab($background-color: $color-quill-gray);
}

.detail-tabs-tab-sage {
    @include detail-tabs-tab($background-color: $color-sage);
}

.detail-tabs-tab-sand-dune {
    @include detail-tabs-tab($background-color: $color-sand-dune);
}

.detail-tabs-tab-slate-gray {
    @include detail-tabs-tab($background-color: $color-slate-gray);
}

.detail-tabs-tab-white {
    @include detail-tabs-tab($background-color: $color-white);
}

.detail-tabs-tab-woodsmoke {
    @include detail-tabs-tab($background-color: $color-woodsmoke);
}