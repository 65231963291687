$vedder-thinking-grid-sort-icon-height: 1.875rem;

.vedder-thinking-grid {
    h2 {
        @include transition(color);

        color: $color-woodsmoke;
        font-weight: 700;
        line-height: 1.15;

        &:hover {
            color: $color-bombay;
        }
    }

    &.vedder-thinking-grid--featured {
        li {
            $first-item-width: 71%;
            $last-child-margin-horizontal: 1.25rem;

            @media (max-width: $bp-desktop) {
                max-width: 60rem;
            }

            &:nth-of-type(n) {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;

                @media (max-width: $bp-desktop) {
                    width: 100%;
                }
            }

            &:nth-of-type(1) {
                width: $first-item-width;

                @media (max-width: $bp-desktop) {
                    width: 100%;
                }

                > :first-child {
                    width: 42%;

                    @media (max-width: $bp-desktop) {
                        order: 1;
                        width: 100%;
                    }
                }

                > :last-child {
                    display: inline-block;
                    margin-left: $last-child-margin-horizontal;
                    margin-right: $last-child-margin-horizontal;
                    width: calc(58% - #{$last-child-margin-horizontal * 2});

                    @media (max-width: $bp-desktop) {
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 1rem;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }

                &:only-child {
                    width: 100%;

                    > :last-child {
                        width: calc(58% - #{$last-child-margin-horizontal});
                        margin-right: 0;
                    }
                }
            }

            &:nth-of-type(2) {
                width: 100 - $first-item-width;

                @media (max-width: $bp-desktop) {
                    margin-top: 1.5rem;
                    width: 100%;
                }

                > :first-child {
                    @media (max-width: $bp-desktop) {
                        order: 1;
                        width: 100%;
                    }
                }

                > :last-child {
                    display: none;

                    @media (max-width: $bp-desktop) {
                        display: inline-block;
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 1rem;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            .abstract {
                font-weight: 300;
                font-size: 1rem;

                ul {
                    display: block;

                    li {
                        display: block;
                        width: auto;
                    }
                }
            }
        }

        ul {
            display: flex;

            @media (max-width: $bp-desktop) {
                display: block;
            }
        }

        h2 {
            font-size: 1.75rem;

            @media (max-width: $bp-desktop) {
                font-size: 1.25rem;
            }
        }
    }

    &.vedder-thinking-grid--secondary {
        margin-top: 0.5rem;
        overflow: hidden;

        .responsive-list {
            display: flex;
        }
        .flex-grid-column {
            width: 32%;
            min-width:32%;
            flex: 1;
            padding: 20px;
        }


        p {
            margin-bottom: 0;
            margin-top: 0;
        }

        .flex-grid-column li {
            break-inside: avoid;
            padding-bottom: 2rem;
            padding-right: 3rem;
        }

        img {
            width: 100%;
        }

        h2 {
            font-size: 1.4375rem;

            @media (max-width: $bp-desktop) {
                font-size: 1.2rem;
            }
        }
    }

    &.vedder-thinking-grid--search-results {
        $first-child-width: 25%;

        margin-top: 1rem;

        ul {
            columns: auto;
        }

        li {
            border-bottom: 1px solid $color-bombay;
            display: flex;
            padding-bottom: 0;

            @media (max-width: $bp-mobile) {
                flex-wrap: wrap;
            }

            > :first-child:not(img) {
                width: $first-child-width;

                @media (max-width: $bp-mobile) {
                    width: 100%;
                }
            }

            > :last-child:not(img) {
                margin-left: 1rem;
                width: 100 - $first-child-width;

                @media (max-width: $bp-mobile) {
                    margin-left: 0;
                    margin-top: 0.5rem;
                    margin-bottom: 1.5rem;
                    width: 100%;
                }
            }

            img {
                float: left;
                width: auto;
                height: 8rem;
                max-width: 25%;
            }
        }
    }
}

.vedder-thinking-grid__tag {
    color: $color-brand-green;
    font-size: 1.1875rem;
    font-weight: 700;
    margin-bottom: 0;

    @media (max-width: $bp-desktop) {
        font-size: 1rem;
    }
}

.vedder-thinking-grid__date {
    color: $color-hoki;
    margin-top: 0;
}

.vedder-thinking-grid__flex-container {
    @media (max-width: $bp-desktop) {
        width: 100%;
    }

    &.vedder-thinking__header {
        width: 100%;
    }
}

.vedder-thinking-grid__filter-list {
    $padding-vertical: 0.5rem;

    padding-bottom: $padding-vertical;
    padding-top: $padding-vertical;
    display: flex;
    flex-wrap: wrap;
}

.vedder-thinking-grid__filter-icon {
    @include circular-background($background-color: $color-bombay, $height: 1.875rem);

    color: $color-white;
    font-size: 1.1rem;
}

.vedder-thinking-grid__filter-list-item {

    margin-right: 1rem;
}

.vedder-thinking-grid__filter-text {
    color: $color-big-stone;
    font-size: 1.1875rem;
    font-weight: 700;
}

.vedder-thinking-grid__sort-list {
    $padding-vertical: 0.5rem;

    margin-top: 2.5rem;
    padding-bottom: $padding-vertical;
    padding-top: $padding-vertical;
    display: flex;
    flex-wrap: wrap;
}

.vedder-thinking-grid__sort-icon {
    @include circular-background($background-color: $color-black-squeeze, $height: $vedder-thinking-grid-sort-icon-height, $transform: rotate(90deg));

    color: $color-pumice;
    font-size: 1.1rem;
}

.vedder-thinking-grid__sort-list-item {
    margin-right: 1rem;
}

.vedder-thinking-grid__sort-text {
    color: $color-woodsmoke;
    font-size: 1.125rem;
    font-weight: 700;
}

.vedder-thinking-grid__sort-button {
    &.vedder-thinking-grid__sort-button--is-active {
        .vedder-thinking-grid__sort-icon {
            @include circular-background($background-color: $color-brand-orange, $height: $vedder-thinking-grid-sort-icon-height, $transform: rotate(90deg) rotatey(180deg));

            color: $color-white;
        }
    }
}
