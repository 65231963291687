@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7f1hbq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?7f1hbq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7f1hbq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7f1hbq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7f1hbq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"],
[class*=" icon-"] {
    color: $color-alto;
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin iconStyle() {
  color: $color-alto;
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%grey-circle-icon {
    $background-color: $color-alto;

    @include circular-background($background-color: $background-color, $height: 2.625rem);
    color: $color-white;
    font-size: 1.85rem;

    @media (max-width: $bp-mobile) {
        @include circular-background($background-color: $background-color, $height: 2.2rem);
        font-size: 1.55rem;
    }
}

@mixin icon($icon-name) {
    @include iconStyle();
    content: $icon-name;
}

.icon-chevron-thin-down {
  &:before {
    content: $icon__chevron-thin-down; 
  }
}
.icon-chevron-thin-left {
  &:before {
    content: $icon__chevron-thin-left; 
  }
}
.icon-chevron-thin-right {
  &:before {
    content: $icon__chevron-thin-right; 
  }
}
.icon-chevron-thin-up {
  &:before {
    content: $icon__chevron-thin-up; 
  }
}
.icon-loupe {
  &:before {
    content: $icon__loupe; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon__hamburger; 
  }
}
.icon-twitter {
  &:before {
    content: $icon__twitter; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon__linkedin; 
  }
}
.icon-down-arrow {
  &:before {
    content: $icon__down-arrow; 
  }
}
.icon-down-arrow-alt {
  &:before {
    content: $icon__down-arrow-alt; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon__down-arrow; 
  }
}
.icon-arrow-down-alt {
  &:before {
    content: $icon__down-arrow-alt; 
  }
}
.icon-close {
  &:before {
    content: $icon__close; 
  }
}
.icon-vcard {
  &:before {
    content: $icon__vcard; 
  }
}
.icon-envelope {
  &:before {
    content: $icon__envelope; 
  }
}
.icon-facebook {
  &:before {
    content: $icon__facebook; 
  }
}
.icon-clock {
  &:before {
    content: $icon__clock; 
  }
}
.icon-printer {
  &:before {
    content: $icon__printer; 
  }
}
.icon-pdf {
  &:before {
    content: $icon__pdf; 
  }
}
.icon-envelope-alt {
  &:before {
    content: $icon__envelope-alt; 
  }
}
.icon-calendar {
  &:before {
    content: $icon__calendar; 
  }
}
.icon-share {
  &:before {
    content: $icon__share; 
  }
}
.icon-google-plus {
  &:before {
    content: $icon__google-plus; 
  }
}
.icon-loading {
  &:before {
    @include constant-rotate;
    content: $icon__loading; 
  }
}
.icon-plus {
  &:before {
    content: $icon__plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon__minus; 
  }
}

.icon-triangle {
  &:before {
    content: "\25ba\fe0e";
    font-family: sans-serif;
  }
}

.icon-down-thin-arrow {
  &:before {
    content: $icon__down-thin-arrow;
  }
}

.icon-open-book {
  &:before {
    content: $icon__open-book;
  }
}

.icon-emailcon {
  &:before {
    content: $icon__email-icon;
  }
}

.icon-vcardcon {
  &:before {
    content: $icon__vcard-icon;
  }
}