.stat__list {
    li {
        span {
            &:nth-of-type(2n+1) {
                $padding-horizontal: 0.4rem;

                color: $color-brand-orange;
                font-size: 1.75rem;
                font-weight: 700;
                padding-left: $padding-horizontal;
                padding-right: $padding-horizontal;
            }

            &:nth-of-type(2n) {
                color: $color-big-stone;
                font-size: 1.0625rem;
                line-height: 1.352941176;
                text-transform: lowercase;
            }
        }
    }
}
