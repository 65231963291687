@function shade($color, $percentage: 80%) {
    @return mix($color, black, $percentage);
}

@function unfold-transition($transition: al) {
    $delay: null;
    $duration: $site-transition-duration;
    $easing: ease-in-out;
    $property: null;

    $default-properties: $property, $duration, $easing, $delay;
    $unfolded-transition: ();

    @for $i from 1 through length($default-properties) {
        $p: null;

        @if $i <= length($transition) {
            $p: nth($transition, $i);
        } @else {
            $p: nth($default-properties, $i);
        }

        $unfolded-transition: append($unfolded-transition, $p);
    }

    @return $unfolded-transition;
}
