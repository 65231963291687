.header-banner {
  background-color: $color-brand-blue;
  color: $color-white;
  padding: 20px 0px;
  position: inherit;
  position: relative;

  .layout__column-main {
    display: flex;
    min-height: 400px;
    z-index: 2;
  }

  &__copy {
    display: flex;
    flex-direction: column;
    min-height: inherit;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 26px;
    line-height: 1.2;
  }

  &__meta-item {
    font-size: 20px;
  }

  &__date {
    display: block;
  }

  &__polygons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0.5;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 600px solid rgba(#276092, 0.5); // Controls Height
      border-right: 350px solid transparent; // Controls Width
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  &__image {
    background: no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-color: $color-brand-green;
  }


  @media (min-width: $bp-mobile) {
    padding: 32px 0px;
    &__title {
      font-size: 32px;
    }
  }
  @media (min-width: $bp-tablet) {
    &__polygons {
      width: 300px;
      opacity: 1;

      &:after {
        
        border-bottom-width: 490px; // Controls Height
        border-right-width: 280px;  // Controls Width
      }
    }

    &__image {
      overflow: hidden;
      clip-path: polygon(0% 0%, 100% 0%, 65.00% 100.00%, 0% 100%);
    }

    .layout__column-main {
      align-items: center;
      min-height: 250px;
      
    }

    &__copy {
      flex-direction: row;
      min-height: auto;
    }
    
    &__title {
      padding-right: 40px;
      flex-grow: 1;
    }
    &__aside {
      flex-shrink: 0;
    }
  }
}