twitterwidget {
    max-width: 100% !important;
}

.vedder-thinking-tab {
    .twitter-tweets {
        background-color: #dcdcdc;
        border-radius: 2px;
        padding: 10px;
    }
}

.vedder-thinking-twitter-feed {
    background-color: #f4f4f4;
    padding: 1rem;
    $color-twitter-blue: #1da1f3;
    $color-twitter-gray: #8e8e8e;

    .icon-twitter {
        color: $color-twitter-blue;
        font-size: 3rem;
    }

    .twitter-screenname {
        color: $color-twitter-gray;

        &:hover {
            color: shade($color-twitter-gray);
        }
    }

    .twitter-content {
        color: $color-twitter-blue;

        &:hover {
            color: shade($color-twitter-blue);
        }
    }

    .twitter-date {
        color: $color-twitter-gray;
    }

    .twitter-nav {
        float: right;
        font-size: 2rem;
        $color: $color-twitter-gray;

        .twitter-nav-up {
            @include transition(transform);
            
            color: $color;
            transform: rotate(270deg) translateY(-2px);

            &.enabled {
                cursor: pointer;

                &:hover {
                    color: shade($color);
                    transform: rotate(-90deg) translateY(-2px) scale(1.1);
                }
            }

            &.disabled {
                color: lighten($color, 20);
            }
        }

        .twitter-nav-down {
            @include transition(transform);
            
            color: $color;
            transform: rotate(90deg);

            &.enabled {
                cursor: pointer;
                
                &:hover {
                    color: shade($color);
                    transform: rotate(90deg) scale(1.1);
                }    
            }

            &.disabled {
                color: lighten($color, 20);
            }
        }
    }
}

.twitter-tweet {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .twitter-icon-container {
        order: 1;
        flex-grow: 1;
    }

    .twitter-body-container {
        order: 2;
        flex-grow: 4;
        
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;

        margin-left: 1rem;
        margin-right: 1rem;

        @media(min-width: $bp-desktop) {
            flex-wrap: nowrap;
        }

        .twitter-screenname-container {
            order: 1;
            flex-grow: 1;
        }

        .twitter-content-container {
            order: 2;
            flex-grow: 1;

            @media(min-width: $bp-desktop) {
                margin-left: 1rem;
            }
        }
    }

    .twitter-nav-container {
        order: 3;
        flex-grow: 1;
    }
}