.modal, .gallery-modal {
    top: 0px;
    position: fixed;
    z-index: 28;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: none;
    padding-top: $site-header-height;
    @media (max-width: $bp-mobile) {
        padding-top: $site-header-height-mobile;
    }

    &--gallery {
        padding-top: 0;
    }

    &__background {
        background-color: rgba(0,0,0,0.6);
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;
    }

    &.privacy-policy {
        background-color: white;
        color: black;
        display: none;
        flex-direction: column;
        gap: 2em;
        height: unset;
        justify-content: center;
        left: 50%;
        padding-top: $site-header-height;
        padding: 3em;
        position: fixed;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 25%;
        z-index: 28;

        @media (max-width: $bp-mobile) {
            width: 66%;
        }
    
        .modal {
            &__button-container {
                display: flex;
                gap: 2em;
                @media (max-width: $bp-mobile) {
                    gap: 50%;
                }
            }
        
            &__submit-btn {
                background-color: #6cc04a;
                bottom: 0;
                color: white;
                cursor: pointer;
                padding: 0.5em;
                width: fit-content;
        
                &:disabled {
                    background-color: dimgrey;
                }
            }
        
            &__close-btn {
                align-self: flex-end;
                background-color: #6cc04a;
                color: white;
                cursor: pointer;
                display: flex;
                padding: 0.5em;
                width: fit-content;
            }
        
            &__privacy-policy-checkbox {
                appearance: checkbox !important;
                margin-right: 0.5em;
            }
        
            &__checkbox-container {
                display: flex;
                flex-direction: row;
                text-align: center;
            }
        
            &__title {
                font-size: 24px;
                text-align: center;
            }
        
            &__privacy-policy {
                color: black;
                a {
                    color: blue;
                }
                @media (max-width: $bp-mobile) {
                    display: none;
                }
            }

            &__mobile-privacy-policy {
                display: none;
                @media (max-width: $bp-mobile) {
                    display: unset;
                }
            }
        }
    }
}