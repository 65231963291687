.gallery {
    margin: 0 auto;
    iframe{
        max-width: 100% !important;
    }
    &-container {
        height: 100%;
        position: fixed;
        margin: 0 auto;
        padding-left: 5%;
        padding-right: 5%;
        top: 2vh;
        width: 90%;
        .nav__slide.slick-slide{
            max-height: 15vh;
        }
        @media (max-width: $bp-tablet) AND (orientation:landscape) {
            padding-left: 0%;
            padding-right: 0%;
            top:2%;
            width:100%;
            img{
                max-height: 200px
            }
            iframe{
                max-height: 150px;
            }
            .nav__slide.slick-slide{
                max-height: 20vh;
            }
         }

        @media (min-width: $bp-desktop) {
            height: auto;
            margin: 0 auto;
            padding: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 90%;
        }
    }

    &__slide {

        //height: 400px !important;
        &__caption {
            background-color: rgba(0, 0, 0, 0.6);
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: auto;
            margin: 0 auto;
            max-height: 320px;
        }

        @media (min-width: $bp-desktop) {
            img {
                max-height: 500px;
            }
        }
    }

    .slick-track {
        display: flex;

        .gallery__slide {
            align-self: flex-end;
        }

    }

    @media (min-width: $bp-desktop) {

        &-container {
            width: 750px;
        }
    }
}

.nav {
    //display: none;
    width: 75%;
    margin: 20px auto;

    .slick-current.nav__slide {
        background-color: $color-alto;
    }


    // .slick-list {
    //   overflow-x: scroll;
    // }
    .slick-next {
        right: -35px;

        &:before {
            @extend .icon-chevron-thin-right;
            font-family: 'vedder-price';
            background: transparent;
            font-size: 30px;
            display: flex;
            justify-content: flex-end;
        }
    }

    .slick-prev {
        left: -35px;

        &:before {
            @extend .icon-chevron-thin-left;
            font-family: 'vedder-price';
            background: transparent;
            font-size: 30px;
        }
    }

    & &__slide {
        //width: 150px !important;
        height: 110px !important;
        margin-right: 10px;
        padding: 7px;

        border-radius: 10px;

        &__image {
            width: 100%;
            height: 100%;
            background-size: cover;
            //padding: 2%;
            box-sizing: border-box;
        }
    }

    .slick-prev, .slick-next {
        height: 27px;
    }

    @media (min-width: $bp-desktop) {
        .slick-prev, .slick-next {
            height: 20px;
        }
    }

    @media (min-width: $bp-desktop) {
        width: 750px;
    }
}

.gallery-button {
    cursor: pointer;
}
