.offices-detail-tab__overview-container {
    section {
        margin-bottom: 2rem;
    }

    section.introduction {
        font-size: 1rem;
        color: $color-mirage;
        line-height: 1.2;
        font-size: 1.125rem;
        font-weight: 400;
        p {
            font-size: inherit;
            font-weight: inherit;
        }
    }

    section.overview {
        font-size: 1rem;
        line-height: 1.8;
        color: $color-woodsmoke;
        font-weight: 300;
        margin-bottom: 0;
        p {
            font-size: inherit;
            font-weight: inherit;
        }
    }
}

.directions-img {
    width: 90%;
    margin-left: 55px;
}
