.standard-search-results {
    color: $color-white;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &::before {
        background-color: $color-big-stone;
        content: "";
        height: 100%;
        position: absolute;
        display: inline-block;
        margin-left: calc(-50vw + 50%);
        margin-right: calc(-50vw + 50%);
        width: 100vw;
        left: 0;
        z-index: -1;
    }

    font-family: "Open Sans", "Avenir Next", "Arial", sans-serif !important;

    .gsc-control-cse {
        font-family: "Open Sans", "Avenir Next", "Arial", sans-serif !important;
        
        .gsc-table-result {
            font-family: "Open Sans", "Avenir Next", "Arial", sans-serif !important;
        }
    }
}

.standard-search-results__container {
    $padding-horizontal: 5%;

    background-color: rgba($color-outer-space, 0.72);
    border-top: 3px solid $color-big-stone;
    padding-bottom: 5%;
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
    padding-top: 2%;

    @media (max-width: $bp-desktop) {
        padding-bottom: 10%;
    }
}

.standard-search-results__results [href*="tel"],
.standard-search-results__result-name {
    @include transition(color);

    font-size: 1rem;

    &:hover {
        color: $color-brand-orange;
    }
}

.standard-search-results__result-email {
    a {
        color: $color-white;
        &:hover {
            color: $color-brand-orange;
        }
    }
}

.standard-search-results__filter-icon {
    @include circular-background($background-color: $color-alto, $height: 1.6875rem);

    font-size: 1rem;
}

.standard-search-results__filter-text,
.standard-search-results__filter-icon {
    color: $color-white;
}

.standard-search-results__filter-text {
    display: inline-block;
    font-size: 1.1875rem;
    font-weight: 700;
    margin-left: 0.2rem;

    @media (max-width: $bp-desktop) {
        font-size: 1rem;
    }
}

// https://bugzilla.mozilla.org/show_bug.cgi?id=984869
.standard-search-results__flex-container {
    align-items: center;
    display: flex;
}

.standard-search-results__filter-button {
    display: inline-flex;
    cursor: pointer;
}

// Professionals Landing modificatons
.standard-search-results {
    &.standard-search-results--professionals-landing {
        table {
            border-collapse: collapse;
            margin-top: 2rem;
            width: 100%;

            @media (max-width: $bp-desktop) {
                width: auto;
            }
            //nested tables on professional landing
            table {
                margin-top: 0;

                tr {
                    border: none;
                }

                td {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        img {
            width: 5.4375rem;
            vertical-align: middle;
        }

        td {
            $padding-vertical: 0.5rem;

            padding-bottom: $padding-vertical;
            padding-top: $padding-vertical;
            position: relative;

            @media (max-width: $bp-desktop) {
                $padding-vertical: 0.25rem;

                padding-bottom: $padding-vertical;
                padding-top: $padding-vertical;
                text-align: left;

                &:not(:first-of-type) {
                    display: block;
                }

                &:first-of-type {
                    padding-right: 1rem;
                    vertical-align: top;
                    width: 1%;
                }
            }
        }

        thead {
            font-family: $font-family-primary--condensed;
            text-transform: uppercase;

            @media (max-width: $bp-desktop) {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
        }

        tbody {
            tr {
                $padding-vertical: 0.5rem;
                $border-color: $color-alto;

                border-bottom: 1px solid $border-color;
                border-top: 1px solid $border-color;
                font-size: 0.875rem;

                @media (max-width: $bp-desktop) {
                    border-top-width: 0;
                    text-align: center;

                    &:first-of-type {
                        border-top: 1px solid $border-color;
                    }
                    //nested tr
                    tr:first-of-type {
                        border-top: none;
                    }
                }
            }
        }

        .standard-search-results__filters {
            position: relative;

            .standard-search-form__clear-input-label {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.standard-search-results {
    &.standard-search-results--site-search {
        .standard-search-results__results {
            margin-top: 0.5rem;
        }

        .gsc-result {
            margin-bottom: 2.5rem;
        }

        .gs-snippet {
            p {
                font-size: 0.9375rem;

                &:first-of-type {
                    margin-top: 0;
                }
            }
        }

        .gs-title {
            a {
                $color: $color-brand-green;

                @include transition(color);

                color: $color;
                font-size: 1.0625rem;
                font-weight: 600;

                &:hover {
                    color: shade($color);
                }
            }
        }

        .standard-search-results__filters {
            position: relative;
        }

        .standard-search-results__clear-all {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
        }
    }
}
