.standard-search-field__input-label input,
.standard-search-field__select-label select {
    box-sizing: border-box;
    color: $color-white;
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0.4rem;
    padding-bottom: 0.25rem;
    // transform: translateY(-50%);
    width: 100%;
}

.standard-search-field__select-label {
    &::before {
        @include icon($icon__chevron-thin-down);
        display: inline-block;
        font-size: 1.75rem;
        position: absolute;
        right: 0;
        top: 0;
    }

    option {
        background-color: $color-white;
    }

    .standard-search-field__select-bottom-border {
        width: unquote("calc(100% - 117px)");
        position:absolute;
        border-bottom: 1px dotted $color-white;
        left: 88px;

       
         @media (max-width: $bp-mobile) {
            left: 70px;
            width: unquote("calc(100% - 99px)");
        }
    }

}

.standard-search-field__input-label,
.standard-search-field__select-label {
    align-items: center;
    display: flex;
    position: relative;

    input {
        border: 1px solid $color-white;
        background-color: $color-off-white;
        color: $color-black;
        padding: 0.5rem;

        &::placeholder {
            color: $color-light-gray;
        }
    }

    select {
        z-index: 10;
    }

}

.standard-search-field__search-input {
    flex-grow: 1;
}

.standard-search-field__label {
    display: inline-block;
}

@mixin standard-search-field-label-text($background-color) {
    @include circular-background($background-color: $background-color, $height: 5rem, $hover: false);

    font-family: $font-family-primary--condensed;
    font-size: 1.1875rem;

    @media (max-width: $bp-mobile) {
        @include circular-background($background-color: $background-color, $height: 4rem, $hover: false);
        font-size: 0.9rem;
    }
}

.standard-search-field__label-text--name {
    @include standard-search-field-label-text($background-color: $color-brand-orange);
}

.standard-search-field__label-text--practice {
    @include standard-search-field-label-text($background-color: $color-corduroy);
}

.standard-search-field__label-text--keyword {
    @include standard-search-field-label-text($background-color: $color-pale-sky);
}

.standard-search-field__label-text--industry {
    @include standard-search-field-label-text($background-color: $color-sand-dune);
}

.standard-search-field__label-text--date {
    @include standard-search-field-label-text($background-color: $color-sage);
}

.standard-search-field__label-text--service-area {
    @include standard-search-field-label-text($background-color: $color-sage);
}
.standard-search-field__label-text--filter {
    @include standard-search-field-label-text($background-color: $color-hippie-green);
}
.standard-search-field__label-text--sortby {
    @include standard-search-field-label-text($background-color: $color-brand-orange);
}
.standard-search-field__label-text--title {
    @include standard-search-field-label-text($background-color: $color-mirage);
}

.standard-search-field__label-text--location, .standard-search-field__label-text--type {
    @include standard-search-field-label-text($background-color: $color-hoki);
}

.standard-search-field__label-text--school {
    @include standard-search-field-label-text($background-color: $color-hippie-green);
}

.standard-search-field__label-text--keyword-vedder-thinking {
    @include standard-search-field-label-text($background-color: $color-slate-gray);
}

.standard-search-field__label-text--industry-vedder-thinking {
    @include standard-search-field-label-text($background-color: $color-sand-dune);
}

.standard-search-field__label-text--professional-vedder-thinking {
    @include standard-search-field-label-text($background-color: $color-hippie-green);
}