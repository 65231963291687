.reader-modal {
    background-color: #fff;
    color: #000;
    padding: 30px;
    position: fixed;
    margin: 0px 25px auto;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 70vh;
    width: 85%;
    overflow-y: auto;
    -webkit-box-shadow: 0 0 33px -7px rgba(0, 0, 0, .75);
    box-shadow: 0 0 33px -7px rgba(0, 0, 0, .75);

    &__primary-heading {
        font-size: 2em;
        
        &__breadcrumb {
            font-size: 22px;
        }
    }

    &__primary-heading,
    &__primary-heading-sub-text {
        color: $color-brand-blue;
    }

    &__article-publication,
    &__article-date {
        font-style: italic;
        color: $color-hoki;
    }

   

    &__content {
        margin: 30px 0px;
    }

    &__close {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &__text {
            margin-right: 10px;
            color: $color-brand-blue;
        }

        &__icon-close {
            @extend %grey-circle-icon;
            font-size: 0.75rem; 
            height: 1.5rem;
            width: 1.5rem;
            cursor: pointer;

            @media (max-width: $bp-mobile) {
                font-size: 0.75rem;
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }

    &__article-rich-text {
        ul {
            color: $color-woodsmoke;
            list-style-type: none;
            padding-left: 24px;
            font-size: 1rem;
            font-weight: 300;

            li {
                list-style-type: none;
                position: relative;    /* It is required for setting position to absolute in the next rule. */
            }
            
            li::before {
                content: '\2022';
                color: $color-woodsmoke;
                position: absolute;
                left: -0.7em;
                font-size: 2.2em;
                line-height: 23px;
            }
        }
    }

    @media (min-width: $bp-desktop) {
        &__content {
            margin: 30px 150px;
        }
    }

    @media (min-width: 80rem) {
        height: 75vh;
    }

    @media (max-width: $bp-mobile) {
        height: 100%;
        top: 0;
        padding: 2rem 0;
        width: 100%;

        &__close {
            padding: 1rem;
            position: fixed;
            right: 0;

            &__text {
                display: none;
            }
        }

        &__content {
            padding: 2rem 3rem 5rem;
        }

        &__article-rich-text {
            // images in mobile view seem to extend outward. 
            // Not sure why but since these are in rich text and are defined in the style attribute we'll have to !important these
            // TODO?
            img {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.reader-view {
    cursor: pointer;
    color: $color-brand-blue;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;

    &__label {
        margin-right: 10px;
    }

    & &__icon {
        font-size: 2rem;
    }
}
